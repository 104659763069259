import React, { useCallback, useEffect, useState } from "react";
import { Checkbox, Modal } from "antd";
import styled from "styled-components";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Search from "antd/lib/input/Search";
import { debounce } from "lodash";
import { useStore } from "../../store";
import useTodo from "../../hookQuery/useTodo";
import Input from "../../react-ui/Input";
import { formatNumberString } from "../../../utils/formatNumberString";
import { stringToFloat } from "../../../utils/stringToFloat";
import PrestationForm from "../Prestations/PrestationForm";
import Eye from "../../react-ui/Icons/Eye";
import { stringSorter } from "../../../utils/stringSorter";
import { numberSorter } from "../../../utils/numberSorter";
import TableContainer from "../../Chiffrage/CostingMovementsCarrycots/TableContainer";
import { getData, postData } from "../../request/instance";
import { fetchPrestationsUrl } from "../../../utils/fetchPrestationsUrl";
import { fetchPrestationAssociatesUrl } from "../../../utils/fetchPrestationAssociatesUrl";
import { fetchProfilsUrl } from "../../../utils/fetchProfilsUrl";
import {
  getFetchUrlIdAndFrom,
  getFrom,
} from "../../../utils/getFetchUrlIdAndFrom";
import { quantityTimeConverter } from "../../../utils/quantityTimeConverter";
import { generateFormData } from "../../../utils/generateFormData";
import { fetchMajorationsUrl } from "../../../utils/fetchMajorationsUrl";

const selector = (state) => ({
  selectedMateriel: state.selectedMateriel,
  formToken: state.formToken,
  isUsingMinutes: state.isUsingMinutes,
  selectedFamily: state.selectedFamily,
});

const debouncedSearch = debounce(({ refetch }) => {
  refetch();
}, 300);

function PrestationAssociates({
  categories,
  unities,
  updatePrestationAssociate,
  from,
  opportunityId,
  entityWorkId,
  faId,
  faIsValidate,
  parent,
  configuration,
  vocabulary,
  defaultMajoration = null,
  setImpactedModelZone,
}) {
  const queryClient = useQueryClient();
  const { selectedMateriel, formToken, isUsingMinutes, selectedFamily } =
    useStore(selector);
  const [prestaAssociateData, setPrestaAssociateData] = useState([]);
  const [isModalPrestationVisible, setIsModalPrestationVisible] =
    useState(false);
  const [showPrestation, setShowPrestation] = useState();
  const [wordEntered, setWordEntered] = useState("");

  const getParentInformations = useCallback(() => {
    let keyId;
    let keyName;
    let id;
    const specialFrom = getFrom({ from, faIsValidate, opportunityId });
    if (specialFrom === "referencing") {
      keyId = "material_opportunity_parent_id";
      id = selectedMateriel?.material_included?.id;
      keyName = "prestation_opportunity";
    } else if (specialFrom === "frameworkAgreement") {
      keyId = "material_framework_agreement_id";
      id = selectedMateriel?.material_included?.id;
      keyName = "prestation_framework_agreement";
    } else {
      keyId =
        parent === "material"
          ? "material_profession_id"
          : "family_profession_id";
      keyName = "prestation_parent";
      id = parent === "material" ? selectedMateriel?.id : selectedFamily?.id;
    }
    return { keyId, keyName, id };
  }, [
    faIsValidate,
    from,
    opportunityId,
    parent,
    selectedFamily?.id,
    selectedMateriel?.id,
    selectedMateriel?.material_included?.id,
  ]);

  const { refetch, data: prestations } = useTodo(
    formToken,
    fetchPrestationsUrl({
      ...getFetchUrlIdAndFrom({
        from,
        opportunityId,
        faIsValidate,
        faId,
        entityWorkId,
      }),
      search: wordEntered,
    }),
    ["Prestations", { from, parent, faId }]
  );

  const { data: profils } = useTodo(
    formToken,
    fetchProfilsUrl({
      ...getFetchUrlIdAndFrom({
        from,
        opportunityId,
        faIsValidate,
        faId,
        entityWorkId,
      }),
    }),
    ["Profils", { from }]
  );

  const { data: prestationsAssociatesApi } = useQuery(
    [
      "PrestationsAssociates",
      {
        from,
        parent,
        faId,
        keyId: getParentInformations().keyId,
        parentId: getParentInformations().id,
      },
    ],
    () =>
      getData(
        formToken,
        fetchPrestationAssociatesUrl({
          ...getFetchUrlIdAndFrom({
            from,
            opportunityId,
            faIsValidate,
            faId,
            entityWorkId,
          }),
          keyId: getParentInformations().keyId,
          parentId: getParentInformations().id,
        })
      )
  );

  const { mutate: createPrestationAssociate } = useMutation(
    (todo) => postData(formToken, "/prestation_associate/create", todo),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("PrestationsAssociates");
        queryClient.invalidateQueries("Prestations");
        if (data && data?.impacted) {
          setImpactedModelZone(data.impacted);
        } else {
          setImpactedModelZone([]);
        }
      },
    }
  );

  const { data: majorationsOpp } = useQuery(
    ["MajorationsOpp", { from }],
    () => getData(formToken, `/majoration_opportunities/${opportunityId}`),
    {
      enabled:
        from === "referencing" ||
        (from === "frameworkAgreement" &&
          opportunityId !== null &&
          !faIsValidate),
    }
  );

  const { data: defaultValueOpp } = useQuery(
    ["DefaultValue", { from }],
    () => getData(formToken, `/default_value_opportunities/${opportunityId}`),
    {
      enabled:
        from === "referencing" ||
        (from === "frameworkAgreement" &&
          opportunityId !== null &&
          !faIsValidate),
    }
  );

  const { data: majorations } = useQuery(
    ["Majorations", { from }],
    () => getData(formToken, fetchMajorationsUrl({ from, id: faId })),
    {
      enabled:
        from === "frameworkAgreement" &&
        (opportunityId === null || faIsValidate),
    }
  );

  const { mutate: createPrestationAssoOpp } = useMutation(
    (todo) =>
      postData(
        formToken,
        "/prestation_associate_opportunity/create_for_referencing",
        todo
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("PrestationsAssociates");
        queryClient.invalidateQueries("Prestations");
      },
    }
  );

  const getAssociateKey = () => {
    if (from === "referencing") return "prestation_associate_opportunity";
    if (from === "frameworkAgreement")
      return "prestation_asso_framework_agreement";
    return "prestation_associate";
  };

  const { mutate: createPrestationAssoFA } = useMutation(
    (todo) =>
      postData(formToken, "/prestation_asso_framework_agreement/create", todo),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("PrestationsAssociates");
        queryClient.invalidateQueries("Prestations");
      },
    }
  );

  const { mutate: removePrestationAssociate } = useMutation(
    (todo) => postData(formToken, `/${getAssociateKey()}/delete`, todo),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("PrestationsAssociates");
        queryClient.invalidateQueries("Prestations");
        if (data && data?.impacted) {
          setImpactedModelZone(data.impacted);
        } else {
          setImpactedModelZone([]);
        }
      },
    }
  );

  useEffect(() => {
    const { keyId, keyName, id } = getParentInformations();

    const prestasAsso =
      prestationsAssociatesApi?.filter((value) => value[keyId] === id) || [];

    setPrestaAssociateData(
      prestations?.map((presta) => {
        const prestaAsso = prestasAsso?.find((asso) =>
          from === "admin"
            ? asso.prestation_parent_id === presta.id
            : asso[`${keyName}`]?.prestation_profession_id === presta.id
        );
        let speedQuantity;
        if (presta?.minutes) {
          speedQuantity = parseFloat(prestaAsso?.speed_quantity) / 60;
        } else {
          speedQuantity = prestaAsso?.speed_quantity;
        }

        const prestaAssoFields = prestaAsso
          ? {
              linked: true,
              asso_id: prestaAsso.id,
              speed: prestaAsso.speed,
              quantity: formatNumberString({
                str:
                  presta.is_package || prestaAsso.speed
                    ? prestaAsso.quantity
                    : quantityTimeConverter({
                        quantity: prestaAsso.quantity,
                        isUsingMinutes,
                      }),
              }),
              speed_quantity: formatNumberString({
                str: speedQuantity,
              }),
              name: from !== "admin" ? prestaAsso[keyName]?.name : presta.name,
            }
          : {};

        return {
          ...presta,
          ...prestaAssoFields,
        };
      })
    );
  }, [
    prestationsAssociatesApi,
    selectedMateriel,
    prestations,
    from,
    isUsingMinutes,
    faIsValidate,
    opportunityId,
    selectedFamily,
    parent,
    getParentInformations,
  ]);

  const getSuffix = (presta) => {
    if (!presta.speed) {
      if (!presta.is_package) return isUsingMinutes ? "min" : "h";
      return "u";
    }

    return (
      unities.find((unity) => unity.id === presta?.unity_profession_id)
        ?.abbreviation || "u"
    );
  };

  const handleUpdate = async (id, newProperties) => {
    updatePrestationAssociate(JSON.stringify({ id, ...newProperties }));
  };

  const handleDelete = async (id) => {
    removePrestationAssociate(
      JSON.stringify(
        from === "referencing" ? { id, referencing: true } : { id }
      )
    );
  };

  const showModalPrestation = (prestation) => {
    setIsModalPrestationVisible(true);
    setShowPrestation(prestations.find((item) => item.id === prestation.id));
  };

  const handleCancelPrestation = () => {
    setIsModalPrestationVisible(false);
  };

  const createForFAAndOpp = (e) => {
    if (from === "referencing")
      createPrestationAssoOpp({
        parent_id: selectedMateriel.material_included.id,
        opportunity_id: opportunityId,
        prestation_profession_id: e.id,
        speed: e.speed,
        speed_quantity: e.speed_quantity,
        quantity: e.quantity,
      });
    else
      createPrestationAssoFA({
        material_framework_agreement_id: selectedMateriel.material_included.id,
        framework_agreement_id: faId,
        prestation_profession_id: e.id,
        speed: e.speed,
        speed_quantity: e.speed_quantity,
        quantity: e.quantity,
      });
  };

  const handlePrestationChecked = (id, checked) => {
    if (checked) {
      const presta = prestations.find((el) => el.id === id);
      if (from !== "admin") {
        createForFAAndOpp(presta);
        return;
      }
      const formData = generateFormData({
        e: presta,
        formName: "prestation_associate",
        excludedKeys: ["quantity", "speed_quantity", "id"],
      });
      formData.append("prestation_associate[prestation_parent_id]", presta.id);
      if (presta.quantity)
        formData.append("prestation_associate[quantity]", presta.quantity);
      if (presta.speed_quantity)
        formData.append(
          "prestation_associate[speed_quantity]",
          presta.speed_quantity
        );
      if (parent === "material")
        formData.append(
          "prestation_associate[material_profession_id]",
          selectedMateriel?.id
        );
      else
        formData.append(
          "prestation_associate[family_profession_id]",
          selectedFamily?.id
        );
      createPrestationAssociate(formData);
    } else {
      const { keyName, id: parentId, keyId } = getParentInformations();
      handleDelete(
        prestationsAssociatesApi.find(
          (el) =>
            el[keyId] === parentId &&
            (from === "admin"
              ? el.prestation_parent_id === id
              : el[`${keyName}`].prestation_profession_id === id)
        ).id
      );
    }
  };

  useEffect(() => {
    debouncedSearch({ refetch });
  }, [wordEntered, refetch]);

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);

    if (searchWord === "") {
      clearInput();
    }
  };
  const clearInput = () => {
    setWordEntered("");
  };

  const getRelevantMajorations = useCallback(() => {
    if (
      from === "referencing" ||
      (from === "frameworkAgreement" && opportunityId !== null && !faIsValidate)
    ) {
      return majorationsOpp;
    }
    if (
      from === "frameworkAgreement" &&
      (opportunityId === null || faIsValidate)
    ) {
      return majorations;
    }
    return undefined;
  }, [from, majorationsOpp, majorations, opportunityId, faIsValidate]);

  const getRelevantDefaultMajoration = useCallback(() => {
    if (from === "referencing" && defaultValueOpp) {
      return defaultValueOpp[0]?.majoration_opportunity_id;
    }
    if (from === "frameworkAgreement" && defaultMajoration) {
      return defaultMajoration.id;
    }
    return undefined;
  }, [from, defaultMajoration, defaultValueOpp]);

  const prestaCols = [
    {
      title: "Intitulé",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => stringSorter(a.name, b.name),
    },
    {
      title: "Forf.",
      dataIndex: "is_package",
      key: "is_package",
      width: "60px",
      hidden:
        !configuration.admin.product_tab.prestation_associate_table.is_package,
      render: (val) => (
        <CenteredContainer>
          <Checkbox checked={val} disabled />
        </CenteredContainer>
      ),
    },
    {
      title: "Vit.",
      dataIndex: "speed",
      key: "speed",
      width: "60px",
      hidden: !configuration.admin.product_tab.prestation_associate_table.speed,
      render: (val, presta) =>
        presta.linked && !presta.is_package ? (
          <CenteredContainer>
            <Checkbox
              disabled={
                from === "frameworkAgreement" && opportunityId && !faIsValidate
              }
              checked={val}
              onChange={({ target: { checked } }) => {
                handleUpdate(presta.asso_id, { speed: checked });
              }}
            />
          </CenteredContainer>
        ) : null,
    },
    {
      title: `Vitesse`,
      dataIndex: "speed_quantity",
      key: "speed_quantity",
      width: "100px",
      hidden:
        !configuration.admin.product_tab.prestation_associate_table
          .speed_quantity,
      render: (val, presta) =>
        presta.linked &&
        !presta.is_package &&
        !(
          !presta.speed ||
          (from === "frameworkAgreement" && opportunityId && !faIsValidate)
        ) ? (
          <Input
            fontSize="10px"
            value={
              presta.speed
                ? formatNumberString({ str: presta.speed_quantity })
                : ""
            }
            suffix={`${getSuffix(presta)}/${presta?.minutes ? "min" : "h"}`}
            size="small"
            textAlign="right"
            onChange={(e) => {
              const prestaUpdated = prestaAssociateData;
              const idxToUpdate = prestaUpdated.findIndex(
                (el) => el.id === presta.id
              );
              prestaUpdated[idxToUpdate].speed_quantity = formatNumberString({
                str: e.target.value,
              });
              setPrestaAssociateData([...prestaUpdated]);
            }}
            onBlur={(e) =>
              handleUpdate(presta.asso_id, {
                speed_quantity: e.target.value
                  ? quantityTimeConverter({
                      quantity: stringToFloat(e.target.value),
                      isUsingMinutes:
                        presta?.minutes === null
                          ? isUsingMinutes
                          : presta?.minutes,
                    })
                  : null,
              })
            }
          />
        ) : null,
    },
    {
      title: "Quantité",
      dataIndex: "quantity",
      key: "quantity",
      width: "70px",
      render: (val, presta) =>
        presta.linked ? (
          <Input
            disabled={
              from === "frameworkAgreement" && opportunityId && !faIsValidate
            }
            fontSize="10px"
            value={formatNumberString({ str: val })}
            suffix={getSuffix(presta)}
            size="small"
            textAlign="right"
            onChange={(e) => {
              const prestaUpdated = prestaAssociateData;
              const idxToUpdate = prestaUpdated.findIndex(
                (el) => el.id === presta.id
              );
              prestaUpdated[idxToUpdate].quantity = formatNumberString({
                str: e.target.value,
              });
              setPrestaAssociateData([...prestaUpdated]);
            }}
            onBlur={(e) =>
              handleUpdate(presta.asso_id, {
                // eslint-disable-next-line no-nested-ternary
                quantity: e.target.value
                  ? presta.is_package || presta.speed
                    ? stringToFloat(e.target.value)
                    : quantityTimeConverter({
                        quantity: stringToFloat(e.target.value),
                        isUsingMinutes,
                        toMinutes: false,
                      })
                  : null,
              })
            }
          />
        ) : null,
    },
    {
      title: "Associer",
      dataIndex: "linked",
      width: "65px",
      align: "center",
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        numberSorter(a.linked, b.linked) || numberSorter(a.asso_id, b.asso_id),
      render: (linked, record) =>
        (from !== "admin" && !selectedMateriel.is_in_framework_agreement) ||
        (from === "frameworkAgreement" &&
          opportunityId &&
          !faIsValidate) ? null : (
          <CenteredContainer>
            <Checkbox
              checked={linked}
              onChange={({ target: { checked } }) =>
                handlePrestationChecked(record.id, checked)
              }
            />
          </CenteredContainer>
        ),
    },
    {
      title: "",
      dataIndex: "show",
      key: "show",
      width: "20px",
      render: (_, presta) => (
        <CenteredContainer>
          <Eye
            onClick={() => {
              showModalPrestation(presta);
            }}
          />
        </CenteredContainer>
      ),
    },
  ].filter((col) => !col.hidden);

  return (
    <>
      <Modal
        width={840}
        open={isModalPrestationVisible}
        footer={null}
        onCancel={handleCancelPrestation}
        maskClosable={false}
      >
        <h3>Information de la {vocabulary?.prestation_lowercase}</h3>
        <PrestationForm
          initialPrestation={showPrestation}
          categories={categories}
          unities={unities}
          profils={profils}
          from={from}
          faIsValidate={faIsValidate}
          majorations={getRelevantMajorations()}
          defaultMajoration={getRelevantDefaultMajoration()}
          hasPrestationIncluded={
            showPrestation?.prestation_included !== null &&
            showPrestation?.prestation_included !== undefined
          }
          configuration={configuration}
          vocabulary={vocabulary}
          isShowing
          entityWorkId={entityWorkId}
          opportunityId={opportunityId}
        />
      </Modal>
      <StyledSearch
        allowClear
        placeholder="Rechercher par nom de prestation ou catégorie"
        value={wordEntered}
        onChange={handleFilter}
      />
      <TableContainer
        bordered={false}
        columns={prestaCols}
        data={prestaAssociateData}
        rowKey="id"
      />
    </>
  );
}

const CenteredContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledSearch = styled(Search)`
  margin: 10px 0;
`;

export default PrestationAssociates;
