import React, { useEffect, useRef, useState } from "react";
import { array, func, number, string } from "prop-types";
import styled from "styled-components";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { profilOpportunitiesColumns } from "../../../constant";
import TableContainer from "../TableContainer";
import { formatNumberString } from "../../../../utils/formatNumberString";
import { multiplyFloat } from "../../../../utils/multiplyFloat";
import { getData, postData } from "../../../request/instance";
import { ShowDeleteConfirm } from "../../../Admin-cf/confirmModal";
import Input from "../../../react-ui/Input";
import Select from "../../../react-ui/Select";
import InputNumber from "../../../react-ui/InputNumber";
import { stringToFloat } from "../../../../utils/stringToFloat";
import ActionsContainer from "../../../react-ui/ActionsContainer";
import Delete from "../../../react-ui/Icons/Delete";

const ProfilMovementTable = React.memo(
  ({
    profils,
    formToken,
    id_opportunity,
    movementDuration,
    movementId,
    handleProfilQuantityChange,
  }) => {
    const queryClient = useQueryClient();
    const [opportunitiesState, setOpportunitiesState] = useState([]);
    const newOpportunityRef = useRef();

    const { data: opportunities } = useQuery(
      `ProfilMovementOpportunities-${movementId}`,
      async () => {
        const data = await getData(
          formToken,
          `/profils_movement_opportunity/${id_opportunity}`
        );

        return data.map((el) => {
          return {
            ...el,
            total_duration_hour: el.total_duration_hour
              ? formatNumberString({
                  str: el.total_duration_hour,
                  nbDecimal: 2,
                })
              : "0",
          };
        });
      },
      {
        refetchOnWindowFocus: false,
      }
    );

    const { mutate: deleteOpportunityMutation } = useMutation(
      (todo) =>
        postData(formToken, "/profils_movement_opportunity/delete", todo),
      {
        onSuccess: () => {
          queryClient.invalidateQueries("MovementOpportunities");
          queryClient.invalidateQueries(
            `ProfilMovementOpportunities-${movementId}`
          );
        },
        onError: () => {
          queryClient.resetQueries("MovementOpportunities");
          queryClient.resetQueries(`ProfilMovementOpportunities-${movementId}`);
        },
      }
    );

    const { mutate: updateOpportunityMutation } = useMutation(
      (todo) =>
        postData(formToken, "/profils_movement_opportunity/update", todo),
      {
        onSuccess: () => {
          queryClient.invalidateQueries("MovementOpportunities");
          queryClient.invalidateQueries(
            `ProfilMovementOpportunities-${movementId}`
          );
        },
        onError: () => {
          queryClient.resetQueries("MovementOpportunities");
          queryClient.resetQueries(`ProfilMovementOpportunities-${movementId}`);
        },
      }
    );

    useEffect(() => {
      if (opportunities) {
        const sortedOpportunities = opportunities.filter(
          (opp) => opp.movement_opportunity_id === movementId
        );
        sortedOpportunities.sort((a, b) => a.id - b.id);
        setOpportunitiesState([...sortedOpportunities]);
      }
    }, [opportunities, movementId]);

    useEffect(() => {
      if (opportunitiesState) {
        const newOpportunities = opportunitiesState.map((opportunity) => {
          const quantity = parseFloat(opportunity.quantity, 10) || 0;
          const totalDuration = movementDuration || 0;

          return {
            ...opportunity,
            total_duration_hour: multiplyFloat({
              floatArray: [quantity, totalDuration],
            }),
          };
        });
        setOpportunitiesState(newOpportunities);
      }
    }, [movementDuration]);

    const updateOpportunity = () => {
      if (profils?.length > 0 && newOpportunityRef.current) {
        updateOpportunityMutation(
          JSON.stringify({
            ...newOpportunityRef.current,
            total_duration_hour: newOpportunityRef.current.total_duration_hour
              ? stringToFloat(newOpportunityRef.current.total_duration_hour)
              : null,
          })
        );
        newOpportunityRef.current = null;
      }
    };

    const deleteOpportunity = (id) =>
      deleteOpportunityMutation(
        JSON.stringify({
          id,
          opportunity_id: id_opportunity,
        })
      );

    const handleProfilChange = ({ e, opportunity }) => {
      const profils_opportunity = profils.find((profil) => profil.id === e);
      return {
        id: opportunity.id,
        name: profils_opportunity.name,
        hourly_rate_cents: profils_opportunity.hourly_rate_cents,
        profil_opportunity_id: e,
        opportunity_id: opportunity.opportunity_id,
      };
    };

    const handleQuantityChange = ({ e, opportunity }) => {
      const quantity = parseFloat(e, 10) || 0;
      const oldQuantity = parseFloat(opportunity.quantity, 10) || 0;
      const totalDuration = movementDuration || 0;

      handleProfilQuantityChange({ quantity, oldQuantity });

      return {
        id: opportunity.id,
        opportunity_id: opportunity.opportunity_id,
        profil_opportunity_id: opportunity.profil_opportunity_id,
        quantity: e,
        total_duration_hour: formatNumberString({
          str: multiplyFloat({
            floatArray: [quantity, totalDuration],
          }),
          nbDecimal: 2,
        }),
      };
    };

    const handleFieldChange = ({ e, opportunity, key, idx }) => {
      const newOpportunities = [...opportunitiesState];
      let newOpportunity = {};

      if (key === "profil")
        newOpportunity = handleProfilChange({ e, opportunity });
      else if (key === "quantity")
        newOpportunity = handleQuantityChange({ e, opportunity });

      newOpportunity = { ...opportunity, ...newOpportunity };
      newOpportunities[idx] = newOpportunity;
      newOpportunityRef.current = newOpportunity;
      setOpportunitiesState(newOpportunities);
    };

    const dataFactory = opportunitiesState.map((opportunity, idx) => ({
      key: opportunity.id,
      profil: (
        <SytledSelect
          value={opportunity.profil_opportunity_id}
          onSelect={(e) => {
            handleFieldChange({
              e,
              idx,
              opportunity,
              key: "profil",
            });
            updateOpportunity();
          }}
          options={profils}
          fontSize="10px"
          size="small"
        />
      ),
      quantity: (
        <StyledInputNumber
          value={opportunity.quantity}
          placeholder="0"
          controls={false}
          onChange={(e) => {
            handleFieldChange({
              e,
              idx,
              opportunity,
              key: "quantity",
            });
          }}
          onBlur={updateOpportunity}
          fontSize="10px"
          size="small"
        />
      ),
      totalDuration: (
        <Input
          value={formatNumberString({
            str: opportunity.total_duration_hour,
            nbDecimal: 2,
            space: true,
          })}
          disabled
          placeholder="0"
          suffix="h"
          fontSize="10px"
          textAlign="right"
        />
      ),
      delete: (
        <ActionsContainer center>
          <Delete
            onClick={() =>
              ShowDeleteConfirm(
                opportunity.id,
                opportunity.id,
                deleteOpportunity
              )
            }
          />
        </ActionsContainer>
      ),
    }));

    return (
      <Container>
        <Wrapper>
          <TableContainer
            columns={profilOpportunitiesColumns}
            data={profils.length > 0 ? dataFactory : []}
          />
        </Wrapper>
      </Container>
    );
  }
);

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 50%;
`;

const SytledSelect = styled(Select)`
  width: 90%;
`;

const StyledInputNumber = styled(InputNumber)`
  width: auto;
`;

ProfilMovementTable.propTypes = {
  profils: array,
  formToken: string.isRequired,
  id_opportunity: string.isRequired,
  movementDuration: number,
  movementId: number.isRequired,
  handleProfilQuantityChange: func.isRequired,
};

ProfilMovementTable.defaultProps = {
  profils: [],
  movementDuration: 0,
};

export default ProfilMovementTable;
