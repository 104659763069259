import React, { useEffect, useRef, useState } from "react";
import { array, bool, func, string } from "prop-types";
import { Checkbox } from "antd";
import styled from "styled-components";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { UserAddOutlined } from "@ant-design/icons";
import { vehiculeColumns } from "../../../constant";
import TableContainer from "../TableContainer";
import { formatNumberString } from "../../../../utils/formatNumberString";
import { multiplyFloat } from "../../../../utils/multiplyFloat";
import { getData, postData } from "../../../request/instance";
import { ShowDeleteConfirm } from "../../../Admin-cf/confirmModal";
import ProfilMovementTable from "./ProfilMovementTable";
import Input from "../../../react-ui/Input";
import Select from "../../../react-ui/Select";
import InputNumber from "../../../react-ui/InputNumber";
import { stringToFloat } from "../../../../utils/stringToFloat";
import ActionsContainer from "../../../react-ui/ActionsContainer";
import Delete from "../../../react-ui/Icons/Delete";

function VehiculeTable({
  opportunities,
  movements,
  zones,
  formToken,
  id_opportunity,
  createOpportunity,
  deleteOpportunity,
  updateOpportunity,
  setIsModalVisibleFor,
  isLoading,
  onProfilAddClick,
  from,
}) {
  const [inputValues, setInputValues] = useState({});
  const queryClient = useQueryClient();
  const [opportunitiesState, setOpportunitiesState] = useState([]);
  const newOpportunityRef = useRef();
  const createNewProfIdx = useRef();

  const { data: profils } = useQuery(
    "Profils",
    () => getData(formToken, `/profil_opportunity/${id_opportunity}`),
    { refetchOnWindowFocus: false, enabled: from !== "configuration" }
  );

  const { mutate: createProfilMovementMutation } = useMutation(
    (todo) => postData(formToken, "/profils_movement_opportunity/create", todo),
    {
      enabled: from !== "configuration",
      onSettled: () => {
        queryClient.invalidateQueries("MovementOpportunities");
        queryClient.invalidateQueries("ProfilMovementOpportunities");
      },
    }
  );

  const handleInputChange = ({ idx, key, value }) => {
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [`${key}-${idx}`]: value,
    }));
  };

  const handleInputBlur = ({ idx, key, value, opportunity }) => {
    const formattedValue = formatNumberString({
      str: value,
      nbDecimal: 2,
      space: true,
    });

    handleInputChange({
      idx,
      key,
      value: formattedValue, // Mettre la valeur formatée dans l'état
    });

    handleFieldChange({
      // Utiliser handleFieldChange pour mettre à jour le champ
      e: { currentTarget: { value: formattedValue } },
      idx,
      opportunity,
      key,
    });

    handleOnBlur(); // Gérer le blur global si nécessaire
  };

  useEffect(() => {
    if (opportunities) {
      const sortedOpportunities = [...opportunities];
      sortedOpportunities.sort((a, b) => a.id - b.id);
      setOpportunitiesState([...sortedOpportunities]);
    }
  }, [opportunities]);

  useEffect(() => {
    if (createNewProfIdx.current !== undefined && isLoading === false) {
      const idx = createNewProfIdx.current;
      createProfilMovementMutation(
        JSON.stringify({
          opportunity_id: id_opportunity,
          profil_opportunity_id: profils[0].id,
          movement_opportunity_id: opportunitiesState[idx].id,
        }),
        {
          onSettled: () => {
            queryClient.invalidateQueries("MovementOpportunities");
            queryClient.invalidateQueries(
              `ProfilMovementOpportunities-${opportunitiesState[idx].id}`
            );
          },
        }
      );
      createNewProfIdx.current = undefined;
    }
  }, [isLoading]);

  const handleOnBlur = () => {
    if (
      movements?.length > 0 &&
      zones?.length > 0 &&
      newOpportunityRef.current
    ) {
      updateOpportunity(newOpportunityRef.current);
      newOpportunityRef.current = null;
    }
  };

  const createProfilMovementOpp = (idx) => {
    if (from === "configuration") return;
    onProfilAddClick(opportunitiesState[idx].id);
    if (profils?.length > 0) {
      if (isLoading) {
        createNewProfIdx.current = idx;
      } else
        createProfilMovementMutation(
          JSON.stringify({
            opportunity_id: id_opportunity,
            profil_opportunity_id: profils[0].id,
            movement_opportunity_id: opportunitiesState[idx].id,
          }),
          {
            onSettled: () => {
              queryClient.invalidateQueries("MovementOpportunities");
              queryClient.invalidateQueries(
                `ProfilMovementOpportunities-${opportunitiesState[idx].id}`
              );
            },
          }
        );
    }
  };

  const handleProfilQuantityChange = ({ quantity, oldQuantity, idx }) => {
    const occupantsNumber = opportunitiesState[idx].occupants_number || 0;
    const newOpportunities = [...opportunitiesState];
    const newOpportunity = {
      ...opportunitiesState[idx],
      occupants_number: occupantsNumber - oldQuantity + quantity,
    };

    newOpportunities[idx] = newOpportunity;
    setOpportunitiesState(newOpportunities);
  };

  const createProfilTable = (_, idx) => {
    return (
      <ProfilMovementTable
        formToken={formToken}
        id_opportunity={id_opportunity}
        profils={profils}
        movementDuration={
          opportunitiesState[idx].total_duration_hour
            ? stringToFloat(opportunitiesState[idx].total_duration_hour)
            : 0
        }
        movementId={opportunitiesState[idx].id}
        handleProfilQuantityChange={({ quantity, oldQuantity }) =>
          handleProfilQuantityChange({ quantity, oldQuantity, idx })
        }
      />
    );
  };

  const handleDistanceChange = ({ e, opportunity }) => {
    const roundtripDistance =
      formatNumberString({
        str: e.currentTarget.value,
        nbDecimal: 2,
      }) || 0;
    const quantity = opportunity.quantity || 0;
    const totalDistance = roundtripDistance * quantity;

    const updatedOpportunity = {
      ...opportunity,
      roundtrip_distance_km: formatNumberString({
        str: e.currentTarget.value,
        nbDecimal: 2,
      }),
      total_distance_km: formatNumberString({
        str: totalDistance,
        nbDecimal: 2,
      }),
    };

    return updatedOpportunity;
  };
  const handleDurationChange = ({ e, opportunity }) => {
    const roundtripDuration =
      formatNumberString({
        str: e.currentTarget.value,
        nbDecimal: 2,
      }) || 0;
    const quantity = opportunity.quantity || 0;

    return {
      roundtrip_duration_hour: formatNumberString({
        str: e.currentTarget.value,
        nbDecimal: 2,
      }),
      total_duration_hour: formatNumberString({
        str: multiplyFloat({
          floatArray: [roundtripDuration, quantity],
        }),
        nbDecimal: 2,
      }),
    };
  };

  const handleQuantityChange = ({ e, opportunity }) => {
    const quantity = parseFloat(e, 10) || 0;
    const roundtripDistance =
      stringToFloat(opportunity.roundtrip_distance_km) || 0;
    const roundtripDuration =
      stringToFloat(opportunity.roundtrip_duration_hour) || 0;

    return {
      quantity,
      total_distance_km: formatNumberString({
        str: multiplyFloat({
          floatArray: [quantity, roundtripDistance],
        }),
        nbDecimal: 2,
      }),
      total_duration_hour: formatNumberString({
        str: multiplyFloat({
          floatArray: [quantity, roundtripDuration],
        }),
        nbDecimal: 2,
      }),
    };
  };

  const handleMovementChange = ({ e, opportunity }) => {
    const movement_tpl = movements.find((movement) => movement.id === e);
    return {
      margin_rate: movement_tpl.margin_rate,
      name: movement_tpl.name,
      id: opportunity.id,
      km_cost: movement_tpl.km_cost,

      opportunity_id: opportunity.opportunity_id,
      movement_tpl_opportunity_id: e,
    };
  };

  const handleSubcontractingChange = ({ e }) => {
    return {
      subcontracting: e.target.checked,
    };
  };

  const handleZoneChange = ({ e }) => {
    return {
      zone_id: e,
    };
  };

  const handleFieldChange = ({ e, opportunity, key, idx }) => {
    const newOpportunities = [...opportunitiesState];
    let newOpportunity = {};

    if (key === "roundtripDistance")
      newOpportunity = handleDistanceChange({ e, opportunity });
    else if (key === "roundtripDuration")
      newOpportunity = handleDurationChange({ e, opportunity });
    else if (key === "quantity")
      newOpportunity = handleQuantityChange({ e, opportunity });
    else if (key === "movement")
      newOpportunity = handleMovementChange({ e, opportunity });
    else if (key === "subcontracting")
      newOpportunity = handleSubcontractingChange({ e });
    else if (key === "zone") newOpportunity = handleZoneChange({ e });

    newOpportunity = { ...opportunity, ...newOpportunity };
    newOpportunities[idx] = newOpportunity;
    newOpportunityRef.current = newOpportunity;
    setOpportunitiesState(newOpportunities);
  };

  const dataFactory = opportunitiesState.map((opportunity, idx) => ({
    key: opportunity.id,
    movement: (
      <SytledSelect
        value={opportunity.movement_tpl_opportunity_id}
        fontSize="10px"
        size="small"
        disabled={from === "configuration"}
        onSelect={(e) => {
          handleFieldChange({
            e,
            idx,
            opportunity,
            key: "movement",
          });
          handleOnBlur();
        }}
        options={movements}
      />
    ),
    roundtripDistance: (
      <Input
        value={
          inputValues[`roundtripDistance-${idx}`] !== undefined
            ? inputValues[`roundtripDistance-${idx}`]
            : opportunity.roundtrip_distance_km
        }
        fontSize="10px"
        textAlign="right"
        placeholder="0"
        disabled={from === "configuration"}
        suffix="km"
        onBlur={(e) => {
          const { value } = e.currentTarget;
          const formattedValue = formatNumberString({
            str: value,
            nbDecimal: 2,
          });
          handleInputChange({
            idx,
            key: "roundtripDistance",
            value: formattedValue,
          });
          handleInputBlur({
            idx,
            key: "roundtripDistance",
            value: formattedValue,
            opportunity,
          });
        }}
        onInput={(e) => {
          const { value } = e.currentTarget;
          const formattedValue = formatNumberString({
            str: value,
            nbDecimal: 2,
          });
          handleInputChange({
            idx,
            key: "roundtripDistance",
            value: formattedValue,
          });
        }}
        onFocus={(e) => {
          const { value } = e.currentTarget;
          const formattedValue = formatNumberString({
            str: value,
            nbDecimal: 2,
          });
          handleInputChange({
            idx,
            key: "roundtripDistance",
            value: formattedValue,
          });
        }}
      />
    ),
    roundtripDuration: (
      <Input
        value={
          inputValues[`roundtripDuration-${idx}`] !== undefined
            ? inputValues[`roundtripDuration-${idx}`]
            : opportunity.roundtrip_duration_hour
        }
        fontSize="10px"
        textAlign="right"
        placeholder="0"
        disabled={from === "configuration"}
        suffix="h"
        onBlur={(e) => {
          const { value } = e.currentTarget;
          const formattedValue = formatNumberString({
            str: value,
            nbDecimal: 2,
          });
          handleInputChange({
            idx,
            key: "roundtripDuration",
            value: formattedValue,
          });
          handleInputBlur({
            idx,
            key: "roundtripDuration",
            value: formattedValue,
            opportunity,
          });
        }}
        onInput={(e) => {
          const { value } = e.currentTarget;
          const formattedValue = formatNumberString({
            str: value,
            nbDecimal: 2,
          });
          handleInputChange({
            idx,
            key: "roundtripDuration",
            value: formattedValue,
          });
        }}
        onFocus={(e) => {
          const { value } = e.currentTarget;
          const formattedValue = formatNumberString({
            str: value,
            nbDecimal: 2,
          });
          handleInputChange({
            idx,
            key: "roundtripDuration",
            value: formattedValue,
          });
        }}
      />
    ),
    quantity: (
      <StyledInputNumber
        value={opportunity.quantity}
        fontSize="10px"
        textAlign="right"
        placeholder="0"
        size="small"
        disabled={from === "configuration"}
        controls={false}
        onInput={(e) =>
          handleFieldChange({
            e,
            idx,
            opportunity,
            key: "quantity",
          })
        }
        onChange={(e) =>
          handleFieldChange({
            e,
            idx,
            opportunity,
            key: "quantity",
          })
        }
        onBlur={handleOnBlur}
      />
    ),
    totalDistance: (
      <Input
        value={opportunity.total_distance_km}
        fontSize="10px"
        textAlign="right"
        disabled
        placeholder="0"
        suffix="km"
      />
    ),
    totalDuration: (
      <Input
        value={opportunity.total_duration_hour}
        fontSize="10px"
        textAlign="right"
        disabled
        placeholder="0"
        suffix="h"
      />
    ),
    occupantsNum: (
      <Input
        value={opportunity.occupants_number}
        disabled
        fontSize="10px"
        textAlign="right"
        placeholder="0"
        size="small"
        onBlur={handleOnBlur}
      />
    ),
    subcontracting: (
      <Checkbox
        checked={opportunity.subcontracting || false}
        disabled={from === "configuration"}
        onChange={(e) => {
          handleFieldChange({
            e,
            idx,
            opportunity,
            key: "subcontracting",
          });
          handleOnBlur();
        }}
        onBlur={handleOnBlur}
      />
    ),
    zone: (
      <SytledSelect
        disabled={from === "configuration"}
        value={opportunity.zone_id}
        onSelect={(e) => {
          handleFieldChange({
            e,
            idx,
            opportunity,
            key: "zone",
          });
          handleOnBlur();
        }}
        keyName="entitled"
        options={zones}
        fontSize="10px"
        size="small"
      />
    ),
    actions: (
      <ActionsContainer center>
        <AddUserIcon onClick={() => createProfilMovementOpp(idx)} />
        <Delete
          onClick={() =>
            ShowDeleteConfirm(
              opportunity.name,
              opportunity.id,
              deleteOpportunity
            )
          }
        />
      </ActionsContainer>
    ),
  }));

  return (
    <TableContainer
      header={{
        title: "Véhicules",
        buttons: [
          {
            buttonLabel: "Ajouter",
            disabled: from === "configuration",
            onClick: () => {
              if (movements?.length > 0 && zones?.length > 0)
                createOpportunity({
                  movement_tpl_opportunity_id: movements[0].id,
                  zone_id: zones[0].id,
                  ...movements[0],
                });
            },
          },
          {
            buttonLabel: "Créer",
            disabled: from === "configuration",
            onClick: () => setIsModalVisibleFor("vehicule"),
          },
        ],
      }}
      columns={vehiculeColumns}
      data={zones.length > 0 ? dataFactory : []}
      expandedRowRender={createProfilTable}
      hideIfNoData={zones.length > 0 && !dataFactory.length}
      tr={2}
    />
  );
}

const SytledSelect = styled(Select)`
  width: 90%;
`;

const StyledInputNumber = styled(InputNumber)`
  width: auto;
`;

const AddUserIcon = styled(UserAddOutlined)`
  font-size: 15px;
  &:hover {
    transform: scale(1.2);
  }
  margin-right: 10px;
`;

VehiculeTable.propTypes = {
  opportunities: array,
  movements: array,
  zones: array,
  formToken: string.isRequired,
  id_opportunity: string.isRequired,
  createOpportunity: func.isRequired,
  deleteOpportunity: func.isRequired,
  updateOpportunity: func.isRequired,
  setIsModalVisibleFor: func.isRequired,
  isLoading: bool.isRequired,
  onProfilAddClick: func.isRequired,
  from: string,
};

VehiculeTable.defaultProps = {
  opportunities: [],
  movements: [],
  zones: [],
  from: "",
};

export default VehiculeTable;
