import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { useMutation } from "react-query";
import { useStore } from "../../store";
import Form from "../../Form";
import { WorkFormFactory } from "./WorkFormfactory";
import Button from "../../react-ui/Button";
import { WorkFormInitialValue } from "./WorkFormInitialValue";
import { putData, patchData } from "../../request/instance";
import SaveButton from "../../react-ui/SaveButton";
import EditButton from "../../react-ui/EditButton";
import { marginToCoeff } from "../../../utils/marginConverter";
import { formatNumberString } from "../../../utils/formatNumberString";

const selector = (state) => ({
  selectedWorkCompany: state.selectedWorkCompany,
});
function WorkEdit({
  token,
  list_api_providers,
  company,
  procurement,
  formEditMode,
  setFormEditMode,
  user_roles,
  worksData,
  setWorksData,
}) {
  const { selectedWorkCompany } = useStore(selector);

  const [formCanceled, setFormCanceled] = useState(false);
  const [useMarginState, setUseMarginState] = useState(
    selectedWorkCompany?.use_margin
  );
  const [useManagementSpecialTVA, setUseManagementSpecialTVA] = useState(
    selectedWorkCompany?.management_special_TVA
  );

  useEffect(() => {
    setUseMarginState(selectedWorkCompany?.use_margin);
    setUseManagementSpecialTVA(selectedWorkCompany?.management_special_TVA);
  }, [selectedWorkCompany]);

  const { mutate: updateCompanyWorkNotSuperAdmin } = useMutation(
    (todo) =>
      patchData(
        token,
        `/admin/companies/${company.id}/company_works/${selectedWorkCompany.id}/margin`,
        todo
      ),
    {
      onSuccess: (payload) => {
        setWorksData((prev) => {
          return prev.map((work) => {
            if (work.work_id === payload.work_id) {
              return {
                ...work,
                default_margin: payload.default_margin,
                manager_licences: payload.manager_licences,
                use_margin: payload.use_margin,
                supervisor_licences: payload.supervisor_licences,
                site_licences: payload.site_licences,
                setup_licences: payload.setup_licences,
                salesman_licences: payload.salesman_licences,
                renting: payload.renting,
                minutes: payload.minutes,
                admin_licences: payload.admin_licences,
                api_providers: payload.api_providers,
                calcul_method: payload.calcul_method,
                management_special_TVA: payload.management_special_TVA,
                default_tva_professions: payload.default_tva_professions,
              };
            }
            return work;
          });
        });
      },
    }
  );
  const { mutate: updateCompanyWork, status } = useMutation(
    (todo) =>
      putData(
        token,
        `/admin/companies/${company.id}/company_works/${selectedWorkCompany.id}`,
        todo
      ),
    {
      onSuccess: (payload) => {
        setWorksData((prev) => {
          return prev.map((work) => {
            if (work.work_id === payload.work_id) {
              return {
                ...work,
                default_margin: payload.default_margin,
                manager_licences: payload.manager_licences,
                use_margin: payload.use_margin,
                supervisor_licences: payload.supervisor_licences,
                site_licences: payload.site_licences,
                setup_licences: payload.setup_licences,
                salesman_licences: payload.salesman_licences,
                renting: payload.renting,
                minutes: payload.minutes,
                admin_licences: payload.admin_licences,
                api_providers: payload.api_providers,
                calcul_method: payload.calcul_method,
                management_special_TVA: payload.management_special_TVA,
                default_tva_professions: payload.default_tva_professions,
              };
            }
            return work;
          });
        });
      },
    }
  );

  const list_api_filter = useMemo(() => {
    return list_api_providers?.find((api) => api.name === "smart4");
  }, [list_api_providers]);

  const handleRegisterOuvrageProfession = (e) => {
    const formData = new FormData();
    // formData.append("company_work[id]", selectedWorkCompany.company_id);

    formData.append("company_work[company_id]", selectedWorkCompany.company_id);
    formData.append("company_work[work_id]", selectedWorkCompany.work_id);
    formData.append("company_work[admin_licences]", e.admin_licences);
    formData.append("company_work[supervisor_licences]", e.supervisor_licences);
    formData.append("company_work[manager_licences]", e.manager_licences);
    formData.append("company_work[salesman_licences]", e.salesman_licences);
    formData.append("company_work[setup_licences]", e.setup_licences);
    formData.append(
      "company_work[management_special_TVA]",
      e.management_special_TVA
    );
    formData.append(
      "company_work[calcul_method]",
      e.calcul_method || selectedWorkCompany.calcul_method
    );
    formData.append(
      "company_work[default_margin]",
      e.use_margin
        ? formatNumberString({
            str: marginToCoeff({
              marginRate: e.default_margin,
              isUsingMargin: e.use_margin,
              calculMethod: e.calcul_method,
            }),
            isCoeff: !e.use_margin,
          })
        : e.default_margin
    );
    formData.append("company_work[renting]", e.renting);
    formData.append("company_work[use_margin]", useMarginState);
    // formData.append(
    //   "company_work[management_special_TVA]",
    //   useManagementSpecialTVA
    // );
    if (e.default_tva_profession === undefined) {
      formData.delete("company_work[default_tva_profession]");
    } else {
      formData.append(
        "company_work[default_tva_profession]",
        e.default_tva_profession
      );
    }

    formData.append("company_work[minutes]", e.minutes);
    if (e.api_providers?.length > 0) {
      const api_providers = `${e.api_providers}, ${list_api_filter.id}`;
      formData.append("company_work[api_provider_ids]", api_providers);
    } else {
      formData.append("company_work[api_provider_ids]", list_api_filter.id);
    }
    formData.append("company_work[site_licences]", e.site_licences);
    if (_.includes(user_roles, "superadmin")) {
      updateCompanyWork(formData);
    } else {
      updateCompanyWorkNotSuperAdmin(formData);
    }
  };

  const onSubmit = async (e) => {
    await handleRegisterOuvrageProfession(e);
    setFormEditMode(!formEditMode);
  };

  return (
    <WorkEditContainer>
      <WorkEditHeader>
        <h3>{selectedWorkCompany?.work.name}</h3>
        {formEditMode ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: 170,
              height: 30,
            }}
          >
            <Button
              label="Annuler"
              fontSize="14px"
              onClick={() => {
                setFormCanceled(!formCanceled);
                setFormEditMode(!formEditMode);
              }}
            />

            <SaveButton
              label="Enregistrer"
              type="submit"
              value="submit"
              fontSize="14px"
              form="company-work-form"
            />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              width: 170,
              height: 30,
            }}
          >
            <EditButton
              label="Editer"
              fontSize="14px"
              onClick={() => setFormEditMode(!formEditMode)}
            />
          </div>
        )}
      </WorkEditHeader>
      <Form
        rows={WorkFormFactory({
          initialWork: selectedWorkCompany,
          list_api_providers,
          procurement,
          useMarginState,
          setUseMarginState,
          useManagementSpecialTVA,
          setUseManagementSpecialTVA,
          user_roles,
        })}
        initialValues={WorkFormInitialValue({
          initialWork: selectedWorkCompany,
          useMarginState,
          formCanceled,
          useManagementSpecialTVA,
        })}
        isShowing={!formEditMode}
        onSubmit={onSubmit}
        id="company-work-form"
      />
    </WorkEditContainer>
  );
}
const WorkEditHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const WorkEditContainer = styled.div`
  border: 1px solid black;
  border-radius: 5px;
  padding: 20px;
`;

export default WorkEdit;
