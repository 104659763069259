import React, { useMemo, useState } from "react";
import _ from "lodash";
import { formatNumberString } from "../../../utils/formatNumberString";

export const WorkFormFactory = ({
  initialWork,
  list_api_providers,
  procurement,
  useMarginState,
  setUseMarginState,
  user_roles,
  useManagementSpecialTVA,
  setUseManagementSpecialTVA,
}) => {
  return useMemo(() => {
    const canEditInput = !_.includes(user_roles, "superadmin");
    if (useMarginState === undefined)
      setUseMarginState(initialWork?.use_margin);
    if (useManagementSpecialTVA === undefined)
      setUseManagementSpecialTVA(initialWork?.management_special_TVA);

    const tvaProfessionSpecial = initialWork?.tva_professions
      ?.filter((tva_profession) => tva_profession.tva_type === "special")
      .map((tva) => ({
        name: tva.name,
        id: tva.id,
      }));
    const firstRow = [
      {
        type: "select",
        item: {
          select: {
            placeholder: "Choisissez",
            disabled: false,
            onChange: () => setUseMarginState(!useMarginState),
          },
          options: [
            { title: "Coefficient", id: false },
            { title: "Pourcentage", id: true },
          ],
          name: "use_margin",
          keyName: "title",
          label: "Type d'affichage",
        },
      },
      {
        type: "select",
        item: {
          select: {
            disabled: canEditInput,
          },
          options: [
            { title: "Non", id: false },
            { title: "Oui", id: true },
          ],
          name: "renting",
          label: "Offre locative",
        },
      },
    ];

    const secondRow = [
      {
        type: "select",
        item: {
          select: {
            placeholder: "Choisissez",
            disabled: false,
          },
          options: [
            { title: "Marge sur prix de vente", id: "selling_price" },
            { title: "Marge sur coût de revient", id: "cost_price" },
          ],
          name: "calcul_method",
          keyName: "title",
          label: "Type de marge",
        },
      },
      {
        type: "input",
        item: {
          input: {
            disabled: false,
            onInput: (e) => {
              e.currentTarget.value = formatNumberString({
                str: e.currentTarget.value,
                nbDecimal: 2,
              });
            },
            suffix: useMarginState && "%",
          },
          name: "default_margin",
          isComputed: useMarginState,
          isMarginRate: useMarginState,
          label: useMarginState
            ? "Pourcentage par défaut"
            : "Coefficient par défaut",
        },
      },
    ];

    const manager_licences_cell = [
      {
        type: "input",
        item: {
          input: {
            onInput: (e) => {
              e.currentTarget.value = formatNumberString({
                str: e.currentTarget.value,
                nbDecimal: 0,
              });
            },
            disabled: canEditInput,
          },
          name: "manager_licences",
          isComputed: true,
          isMarginRate: true,
          label: "Manager",
        },
      },
    ];
    const thirdRow = [
      {
        type: "select",
        item: {
          select: {
            disabled: false,
          },
          options: [
            { title: "Minutes", id: true },
            { title: "Heures", id: false },
          ],
          name: "minutes",
          keyName: "title",
          label: "Affichage du temps",
        },
      },
      ...(!procurement ? manager_licences_cell : []),
    ];

    const fourthRow = [
      {
        type: "input",
        item: {
          input: {
            disabled: canEditInput,
            onInput: (e) => {
              e.currentTarget.value = formatNumberString({
                str: e.currentTarget.value,
                nbDecimal: 0,
              });
            },
          },
          name: "admin_licences",
          isComputed: true,
          isMarginRate: true,
          label: "Administrateur",
        },
      },
      {
        type: "input",
        item: {
          input: {
            disabled: canEditInput,

            onInput: (e) => {
              e.currentTarget.value = formatNumberString({
                str: e.currentTarget.value,
                nbDecimal: 0,
              });
            },
          },
          name: "salesman_licences",
          label: "Commercial",
        },
      },
    ];

    const fifthRow = [
      {
        type: "input",
        item: {
          input: {
            disabled: canEditInput,
            onInput: (e) => {
              e.currentTarget.value = formatNumberString({
                str: e.currentTarget.value,
                nbDecimal: 0,
              });
            },
          },
          name: "setup_licences",
          label: "Paramétreur",
        },
      },
      {
        type: "input",
        item: {
          input: {
            onInput: (e) => {
              e.currentTarget.value = formatNumberString({
                str: e.currentTarget.value,
                nbDecimal: 0,
              });
            },
            disabled: canEditInput,
          },
          name: "supervisor_licences",
          label: "Superviseur",
        },
      },
    ];

    const ProcurementRow = [
      {
        type: "input",
        item: {
          input: {
            onInput: (e) => {
              e.currentTarget.value = formatNumberString({
                str: e.currentTarget.value,
                nbDecimal: 0,
              });
            },
            disabled: canEditInput,
          },
          name: "site_licences",
          label: "Sites",
        },
      },
    ];

    const sixthRow = [
      {
        type: "select",
        item: {
          select: {
            width: "50%",
            disabled: false,
            onChange: () =>
              setUseManagementSpecialTVA(!useManagementSpecialTVA),
          },
          options: [
            { title: "Non", id: false },
            { title: "Oui", id: true },
          ],
          name: "management_special_TVA",
          label: "Gestion de plusieurs TVA",
        },
      },
      ...(useManagementSpecialTVA
        ? [
            {
              type: "select",
              item: {
                select: {
                  disabled: false,
                },
                options: tvaProfessionSpecial.concat({
                  name: "Non éligible à la TVA spéciale",
                  id: "no_tva_special",
                }),

                name: "default_tva_profession",
                keyName: "name",
                label: "TVA par défaut",
              },
            },
          ]
        : []),
    ];

    const seventhRow = [
      {
        type: "select",
        item: {
          select: {
            placeholder: "Choisissez vos api",
            disabled: canEditInput,
            mode: "multiple",
          },
          options: list_api_providers.filter((api) => api.name !== "smart4"),
          name: "api_providers",
          keyName: "name",
          label: "Api externe",
        },
      },
    ];

    const result = [];

    if (initialWork?.name !== "Sécurité humaine") {
      result.push({ columns: firstRow, key: "use_margin+renting" });
      result.push({ columns: secondRow, key: "calcul_method+default_margin" });
    }

    if (procurement) {
      if (initialWork?.name !== "Sécurité humaine") {
        result.push({ columns: thirdRow, key: "minutes" });
      }
    } else if (initialWork?.name !== "Sécurité humaine") {
      result.push(
        { columns: thirdRow, key: "minutes+manager_licences" },
        { columns: fourthRow, key: "admin_licences+salesman_licences" },
        { columns: fifthRow, key: "setup_licences+supervisor_licences" }
      );
    } else {
      result.push(
        { columns: fourthRow, key: "admin_licences+salesman_licences" },
        { columns: fifthRow, key: "setup_licences+supervisor_licences" },
        { columns: manager_licences_cell, key: "manager_licences" }
      );
    }

    if (procurement) {
      result.push({ columns: ProcurementRow, key: "site_licences" });
    }

    if (initialWork?.name !== "Sécurité humaine") {
      result.push(
        {
          columns: sixthRow,
          key: "management_special_TVA+default_tva_profession",
        },
        {
          columns: seventhRow,
          key: "api_providers",
        }
      );
    }

    return result;
  }, [initialWork, useMarginState, useManagementSpecialTVA]);
};
