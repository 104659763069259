import React, { useEffect, useRef, useState } from "react";
import { array, func, number, string } from "prop-types";
import { Checkbox } from "antd";
import styled from "styled-components";
import { carrycotsColumns, quotationCarrycotColumns } from "../../../constant";
import TableContainer from "../TableContainer";
import { formatNumberString } from "../../../../utils/formatNumberString";
import { ShowDeleteConfirm } from "../../../Admin-cf/confirmModal";
import {
  centsToEuros,
  eurosToCents,
} from "../../../../utils/currencyConverter";
import { stringToFloat } from "../../../../utils/stringToFloat";
import Input from "../../../react-ui/Input";
import Select from "../../../react-ui/Select";
import InputNumber from "../../../react-ui/InputNumber";
import Delete from "../../../react-ui/Icons/Delete";
import ActionsContainer from "../../../react-ui/ActionsContainer";

function CarrycotTable({
  opportunities,
  carrycots,
  profils,
  zones,
  createOpportunity,
  deleteOpportunity,
  updateOpportunity,
  setIsModalVisible,
  faId,
  from,
}) {
  const [inputValues, setInputValues] = useState({});
  const [opportunitiesState, setOpportunitiesState] = useState([]);
  const newOpportunityRef = useRef();

  useEffect(() => {
    if (opportunities) {
      const sortedOpportunities = [...opportunities];
      sortedOpportunities.sort((a, b) => a.id - b.id);
      setOpportunitiesState([...sortedOpportunities]);
    }
  }, [opportunities]);

  const handleOnBlur = () => {
    if (profils?.length > 0 && zones?.length > 0 && newOpportunityRef.current) {
      updateOpportunity(newOpportunityRef.current);
      newOpportunityRef.current = null;
    }
  };

  const handleCarrycotChange = ({ e, opportunity }) => {
    const { id, ...carrycot } = carrycots.find((el) => el.id === e);
    const profil_opportunity_id =
      carrycot.profil_opportunity_id ||
      profils.find(
        (el) =>
          el.profil_profession_id ===
          carrycot.carrycot_profession?.profil_profession_id
      )?.id ||
      profils[0].id;
    return {
      ...opportunity,
      carrycot_tpl_opportunity_id: e,
      ...carrycot,
      profil_opportunity_id: faId ? null : profil_opportunity_id,
      daily_cost_cents: formatNumberString({
        str: centsToEuros(carrycot.daily_cost_cents),
        nbDecimal: 2,
      }),
      movement_cost_cents: formatNumberString({
        str: centsToEuros(carrycot.movement_cost_cents),
        nbDecimal: 2,
      }),
    };
  };

  const handleProfilChange = ({ e, opportunity }) => {
    return {
      ...opportunity,
      profil_opportunity_id: e,
    };
  };

  const handleDurationChange = ({ e, opportunity }) => {
    return {
      ...opportunity,
      duration: e.currentTarget.value,
    };
  };

  const handleDailyCostChange = ({ e, opportunity }) => {
    return {
      ...opportunity,
      daily_cost_cents: formatNumberString({
        str: e.currentTarget.value,
        nbDecimal: 2,
      }),
    };
  };

  const handleMovementCostChange = ({ e, opportunity }) => {
    return {
      ...opportunity,
      movement_cost_cents: formatNumberString({
        str: e.currentTarget.value,
        nbDecimal: 2,
      }),
    };
  };

  const handleQuantityChange = ({ e, opportunity }) => {
    return {
      ...opportunity,
      quantity: formatNumberString({
        str: e.currentTarget.value,
        nbDecimal: 2,
      }),
    };
  };

  const handleDayQuantityChange = ({ e, opportunity }) => {
    return {
      ...opportunity,
      day_quantity: e.currentTarget.value,
    };
  };

  const handleRoundtripQuantityChange = ({ e, opportunity }) => {
    return {
      ...opportunity,
      roundtrip_quantity: formatNumberString({
        str: e.currentTarget.value,
        nbDecimal: 2,
      }),
    };
  };

  const handleSubcontractingChange = ({ e, opportunity }) => {
    return {
      ...opportunity,
      subcontracting: e.target.checked,
    };
  };

  const handleZoneChange = ({ e, opportunity }) => {
    return {
      ...opportunity,
      zone_id: e,
    };
  };

  const handleFieldChange = ({ e, opportunity, key, idx }) => {
    const newOpportunities = [...opportunitiesState];
    let newOpportunity = { ...opportunity };

    if (key === "carrycot")
      newOpportunity = handleCarrycotChange({ e, opportunity });
    else if (key === "profil")
      newOpportunity = handleProfilChange({ e, opportunity });
    else if (key === "duration")
      newOpportunity = handleDurationChange({ e, opportunity });
    else if (key === "dailyCost")
      newOpportunity = handleDailyCostChange({ e, opportunity });
    else if (key === "movementCost")
      newOpportunity = handleMovementCostChange({ e, opportunity });
    else if (key === "dayQuantity")
      newOpportunity = handleDayQuantityChange({ e, opportunity });
    else if (key === "quantity")
      newOpportunity = handleQuantityChange({ e, opportunity });
    else if (key === "roundtripQuantity")
      newOpportunity = handleRoundtripQuantityChange({ e, opportunity });
    else if (key === "subcontracting")
      newOpportunity = handleSubcontractingChange({ e, opportunity });
    else if (key === "zone")
      newOpportunity = handleZoneChange({ e, opportunity });

    newOpportunities[idx] = newOpportunity;
    newOpportunityRef.current = formatOpportunityToUpdate(newOpportunity);
    setOpportunitiesState(newOpportunities);
  };

  const handleInputChange = ({ idx, key, value }) => {
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [`${key}-${idx}`]: value,
    }));
  };

  const handleInputBlur = ({ idx, key, value, opportunity }) => {
    const floatValue = stringToFloat(value);
    const formattedValue = formatNumberString({
      str: value,
      nbDecimal: 2,
      space: true,
    });

    handleInputChange({
      idx,
      key,
      value: formattedValue, // Mettre la valeur formatée dans l'état
    });

    handleFieldChange({
      // Utiliser handleFieldChange pour mettre à jour le champ
      e: { currentTarget: { value: floatValue } },
      idx,
      opportunity,
      key,
    });

    handleOnBlur(); // Gérer le blur global si nécessaire
  };

  const formatOpportunityToUpdate = (opportunity) => {
    return {
      ...opportunity,
      duration: opportunity.duration
        ? stringToFloat(opportunity.duration)
        : null,
      daily_cost_cents: opportunity.daily_cost_cents
        ? formatNumberString({
            str: eurosToCents(opportunity.daily_cost_cents),
            nbDecimal: 2,
          })
        : null,
      movement_cost_cents: opportunity.movement_cost_cents
        ? formatNumberString({
            str: eurosToCents(opportunity.movement_cost_cents),
            nbDecimal: 2,
          })
        : null,
      day_quantity: opportunity.day_quantity
        ? stringToFloat(opportunity.day_quantity)
        : null,
      roundtrip_quantity: opportunity.roundtrip_quantity
        ? formatNumberString({
            str: opportunity.roundtrip_quantity,
            nbDecimal: 2,
          })
        : null,
    };
  };

  const dataFactory = opportunitiesState.map((opportunity, idx) => ({
    key: opportunity.id,
    carrycot: (
      <SytledSelect
        value={opportunity.carrycot_tpl_opportunity_id}
        onSelect={(e) => {
          handleFieldChange({
            e,
            idx,
            opportunity,
            key: "carrycot",
          });
          handleOnBlur();
        }}
        options={carrycots}
        fontSize="10px"
        size="small"
        disabled={from === "configuration"}
      />
    ),
    profil: (
      <SytledSelect
        value={opportunity.profil_opportunity_id}
        onSelect={(e) => {
          handleFieldChange({
            e,
            idx,
            opportunity,
            key: "profil",
          });
          handleOnBlur();
        }}
        options={profils}
        fontSize="10px"
        size="small"
        disabled={from === "configuration"}
      />
    ),
    duration: (
      <Input
        value={
          inputValues[`duration-${idx}`] !== undefined
            ? inputValues[`duration-${idx}`]
            : opportunity.duration
        }
        placeholder="0"
        suffix="h"
        onBlur={(e) => {
          const { value } = e.currentTarget;
          const formattedValue = formatNumberString({
            str: value,
            nbDecimal: 2,
          });
          handleInputChange({
            idx,
            key: "duration",
            value: formattedValue,
          });
          handleInputBlur({
            idx,
            key: "duration",
            value: formattedValue,
            opportunity,
          });
        }}
        onInput={(e) => {
          const { value } = e.currentTarget;
          const formattedValue = formatNumberString({
            str: value,
            nbDecimal: 2,
          });
          handleInputChange({
            idx,
            key: "duration",
            value: formattedValue,
          });
        }}
        onFocus={(e) => {
          const { value } = e.currentTarget;
          const formattedValue = formatNumberString({
            str: value,
            nbDecimal: 2,
          });
          handleInputChange({
            idx,
            key: "duration",
            value: formattedValue,
          });
        }}
        fontSize="10px"
        textAlign="right"
        disabled={from === "configuration"}
      />
    ),
    dailyCost: (
      <Input
        value={
          inputValues[`dailyCost-${idx}`] !== undefined
            ? inputValues[`dailyCost-${idx}`]
            : opportunity.daily_cost_cents
        }
        placeholder="0"
        suffix="€"
        onBlur={(e) => {
          const { value } = e.currentTarget;
          const formattedValue = formatNumberString({
            str: value,
            nbDecimal: 2,
          });
          handleInputChange({
            idx,
            key: "dailyCost",
            value: formattedValue,
          });
          handleInputBlur({
            idx,
            key: "dailyCost",
            value: formattedValue,
            opportunity,
          });
        }}
        onInput={(e) => {
          const { value } = e.currentTarget;
          const formattedValue = formatNumberString({
            str: value,
            nbDecimal: 2,
          });
          handleInputChange({
            idx,
            key: "dailyCost",
            value: formattedValue,
          });
        }}
        onFocus={(e) => {
          const { value } = e.currentTarget;
          const formattedValue = formatNumberString({
            str: value,
            nbDecimal: 2,
          });
          handleInputChange({
            idx,
            key: "dailyCost",
            value: formattedValue,
          });
        }}
        fontSize="10px"
        textAlign="right"
        disabled={from === "configuration"}
      />
    ),
    movementCost: (
      <Input
        value={
          inputValues[`movementCost-${idx}`] !== undefined
            ? inputValues[`movementCost-${idx}`]
            : opportunity.movement_cost_cents
        }
        placeholder="0"
        suffix="€"
        onBlur={(e) => {
          const { value } = e.currentTarget;
          const formattedValue = formatNumberString({
            str: value,
            nbDecimal: 2,
          });
          handleInputChange({
            idx,
            key: "movementCost",
            value: formattedValue,
          });
          handleInputBlur({
            idx,
            key: "movementCost",
            value: formattedValue,
            opportunity,
          });
        }}
        onInput={(e) => {
          const { value } = e.currentTarget;
          const formattedValue = formatNumberString({
            str: value,
            nbDecimal: 2,
          });
          handleInputChange({
            idx,
            key: "movementCost",
            value: formattedValue,
          });
        }}
        onFocus={(e) => {
          const { value } = e.currentTarget;
          const formattedValue = formatNumberString({
            str: value,
            nbDecimal: 2,
          });
          handleInputChange({
            idx,
            key: "movementCost",
            value: formattedValue,
          });
        }}
        fontSize="10px"
        textAlign="right"
        disabled={from === "configuration"}
      />
    ),
    dayQuantity: (
      <Input
        value={
          inputValues[`dayQuantity-${idx}`] !== undefined
            ? inputValues[`dayQuantity-${idx}`]
            : opportunity.day_quantity
        }
        placeholder="0"
        onBlur={(e) => {
          const { value } = e.currentTarget;
          const formattedValue = formatNumberString({
            str: value,
            nbDecimal: 2,
          });
          handleInputChange({
            idx,
            key: "dayQuantity",
            value: formattedValue,
          });
          handleInputBlur({
            idx,
            key: "dayQuantity",
            value: formattedValue,
            opportunity,
          });
        }}
        onInput={(e) => {
          const { value } = e.currentTarget;
          const formattedValue = formatNumberString({
            str: value,
            nbDecimal: 2,
          });
          handleInputChange({
            idx,
            key: "dayQuantity",
            value: formattedValue,
          });
        }}
        onFocus={(e) => {
          const { value } = e.currentTarget;
          const formattedValue = formatNumberString({
            str: value,
            nbDecimal: 2,
          });
          handleInputChange({
            idx,
            key: "dayQuantity",
            value: formattedValue,
          });
        }}
        fontSize="10px"
        textAlign="right"
        size="small"
        disabled={from === "configuration"}
      />
    ),
    quantity: (
      <StyledInputNumber
        value={
          inputValues[`quantity-${idx}`] !== undefined
            ? inputValues[`quantity-${idx}`]
            : opportunity.quantity
        }
        placeholder="0"
        controls={false}
        onBlur={(e) => {
          const { value } = e.currentTarget;
          const formattedValue = formatNumberString({
            str: value,
            nbDecimal: 2,
          });
          handleInputChange({
            idx,
            key: "quantity",
            value: formattedValue,
          });
          handleInputBlur({
            idx,
            key: "quantity",
            value: formattedValue,
            opportunity,
          });
        }}
        onInput={(e) => {
          const { value } = e.currentTarget;
          const formattedValue = formatNumberString({
            str: value,
            nbDecimal: 2,
          });
          handleInputChange({
            idx,
            key: "quantity",
            value: formattedValue,
          });
        }}
        onFocus={(e) => {
          const { value } = e.currentTarget;
          const formattedValue = formatNumberString({
            str: value,
            nbDecimal: 2,
          });
          handleInputChange({
            idx,
            key: "quantity",
            value: formattedValue,
          });
        }}
        fontSize="10px"
        size="small"
        disabled={from === "configuration"}
      />
    ),
    roundtripQuantity: (
      <Input
        value={
          inputValues[`roundtripQuantity-${idx}`] !== undefined
            ? inputValues[`roundtripQuantity-${idx}`]
            : opportunity.roundtrip_quantity
        }
        placeholder="0"
        controls={false}
        onBlur={(e) => {
          const { value } = e.currentTarget;
          const formattedValue = formatNumberString({
            str: value,
            nbDecimal: 0,
          });
          handleInputChange({
            idx,
            key: "roundtripQuantity",
            value: formattedValue,
          });
          handleInputBlur({
            idx,
            key: "roundtripQuantity",
            value: formattedValue,
            opportunity,
          });
        }}
        onInput={(e) => {
          const { value } = e.currentTarget;
          const formattedValue = formatNumberString({
            str: value,
            nbDecimal: 0,
          });
          handleInputChange({
            idx,
            key: "roundtripQuantity",
            value: formattedValue,
          });
        }}
        onFocus={(e) => {
          const { value } = e.currentTarget;
          const formattedValue = formatNumberString({
            str: value,
            nbDecimal: 0,
          });
          handleInputChange({
            idx,
            key: "roundtripQuantity",
            value: formattedValue,
          });
        }}
        fontSize="10px"
        textAlign="right"
        size="small"
        disabled={from === "configuration"}
      />
    ),
    subcontracting: (
      <Checkbox
        checked={opportunity.subcontracting || false}
        onChange={(e) =>
          handleFieldChange({
            e,
            idx,
            opportunity,
            key: "subcontracting",
          })
        }
        onBlur={handleOnBlur}
        disabled={from === "configuration"}
      />
    ),
    zone: (
      <SytledSelect
        value={opportunity.zone_id}
        onSelect={(e) => {
          handleFieldChange({
            e,
            idx,
            opportunity,
            key: "zone",
          });
          handleOnBlur();
        }}
        options={zones}
        keyName="entitled"
        fontSize="10px"
        size="small"
        disabled={from === "configuration"}
      />
    ),
    actions: (
      <ActionsContainer center>
        <Delete
          onClick={() =>
            ShowDeleteConfirm(
              opportunity.name,
              opportunity.id,
              deleteOpportunity
            )
          }
        />
      </ActionsContainer>
    ),
  }));

  return (
    <TableContainer
      header={{
        buttons: [
          {
            buttonLabel: "Ajouter",
            disabled: from === "configuration",
            onClick: () => {
              if (
                carrycots?.length > 0 &&
                zones.length > 0 &&
                (profils.length > 0 || !!faId)
              )
                createOpportunity({
                  carrycot_tpl_opportunity_id: carrycots[0].id,
                  zone_id: zones[0].id,
                  ...(faId ? {} : { profil_opportunity_id: profils[0].id }),
                  ...carrycots[0],
                });
            },
          },
          {
            buttonLabel: "Créer",
            disabled: from === "configuration",
            onClick: () => setIsModalVisible(true),
          },
        ],
      }}
      columns={faId ? quotationCarrycotColumns : carrycotsColumns}
      data={
        zones.length > 0 && (profils.length > 0 || !!faId) ? dataFactory : []
      }
      hideIfNoData={
        zones.length > 0 &&
        (profils.length > 0 || !!faId) &&
        !dataFactory.length
      }
      tr={2}
    />
  );
}

const SytledSelect = styled(Select)`
  width: 90%;
`;

const StyledInputNumber = styled(InputNumber)`
  width: auto;
`;

CarrycotTable.propTypes = {
  opportunities: array,
  carrycots: array,
  profils: array,
  zones: array,
  createOpportunity: func.isRequired,
  deleteOpportunity: func.isRequired,
  updateOpportunity: func.isRequired,
  setIsModalVisible: func.isRequired,
  faId: number,
  from: string,
};

CarrycotTable.defaultProps = {
  opportunities: [],
  carrycots: [],
  profils: [],
  zones: [],
  faId: undefined,
  from: "",
};

export default CarrycotTable;
