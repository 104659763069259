import React, { useState, useEffect, useMemo, useCallback } from "react";
import styled from "styled-components";
import { QueryClient, useQuery, useQueryClient } from "react-query";
import Search from "antd/lib/input/Search";
import { debounce } from "lodash";
import TitleContainer from "../../react-ui/TitleContainer";
import Separator from "../../react-ui/Separator";
import OuvrageArray from "./OuvrageArray";
import { getData } from "../../request/instance";
import OuvrageDetails from "./OuvrageDetails";
import AddButton from "../../react-ui/AddButton";
import OuvrageUpdate from "./OuvrageUpdate";
import useTodo from "../../hookQuery/useTodo";
import { getFetchUrlIdAndFrom } from "../../../utils/getFetchUrlIdAndFrom";
import { fetchUnitiesUrl } from "../../../utils/fetchUnitiesUrl";
import { getUrlParams } from "../../utils";
import Button from "../../react-ui/Button";
import { Loading } from "../../loading";

const debouncedSearch = debounce(({ refetch_ouvrage_professions }) => {
  refetch_ouvrage_professions();
}, 300);

function Ouvrages({
  token,
  entityId,
  configuration,
  entityWorkId = getUrlParams(),
  vocabulary,
  opportunityId = null,
  faIsValidate = false,
  faId = null,
  from = "admin",
  group,
}) {
  const determineSingularWording = useCallback(() => {
    if (group) return vocabulary.group;
    return vocabulary.ouvrage;
  }, [group, vocabulary]);

  const determinePluralWording = useCallback(() => {
    if (group) return vocabulary.groups;
    return vocabulary.ouvrages;
  }, [group, vocabulary]);
  const queryClient = useQueryClient();
  const [ouvrageSelected, setOuvrageSelected] = useState([]);
  const [ouvrages, setOuvrages] = useState([]);
  const [ouvrageChildId, setOuvrageChildId] = useState(null);
  const [ouvrageDetails, setOuvrageDetails] = useState([]);
  const [ouvrageDynamicLabel, setOuvrageDynamicLabel] = useState(
    `${determineSingularWording()} modification`
  );

  const [ouvrageDetailsStatus, setOuvrageDetailsStatus] = useState("empty");
  const [wordEntered, setWordEntered] = useState("");
  const [statusOuvrage, setStatusOuvrage] = useState("");

  useEffect(() => {
    if (ouvrageDetailsStatus === "create") {
      setOuvrageDynamicLabel(`${determineSingularWording()} création`);
    }
  }, [ouvrageDetailsStatus]);

  const { data: unities } = useTodo(
    token,
    fetchUnitiesUrl({
      ...getFetchUrlIdAndFrom({
        from,
        opportunityId,
        faIsValidate,
        faId,
        entityWorkId,
      }),
    }),
    ["Unities", { from }]
  );

  const ouvrageProfessionsQueryKey = useMemo(
    () => ["OuvrageProfessions", entityId],
    [entityId]
  );

  const { data: categories } = useTodo(
    token,
    `/category_profession?entity_work_id=${entityWorkId}`,
    "Categories"
  );

  // const {
  //   data: ouvrage_professions,
  //   refetch: refetch_ouvrage_professions,
  //   isLoading: ouvragesLoading,
  // } = useQuery(
  //   ouvrageProfessionsQueryKey,
  //   () =>
  //     getData(
  //       token,
  //       `/ouvrage_professions?entity_id=${entityId}&entity_work_id=${entityWorkId}&search=${wordEntered}`
  //     ),
  //   {
  //     onSuccess: (payload) => {
  //       setOuvrages(payload);
  //       if (ouvrageSelected.length === 0) {
  //         setOuvrageSelected([payload[0]]);
  //       }
  //     },
  //   }
  // );

  const ouvrageProfessionQueryKey = useMemo(
    () => ["OuvrageProfession", ouvrageSelected[0]?.id],
    [ouvrageSelected]
  );

  const { data: ouvrage_profession, refetch: ouvrage_refetch } = useQuery(
    ouvrageProfessionQueryKey,
    () => getData(token, `/ouvrage_profession/${ouvrageSelected[0]?.id}`),
    {
      onSuccess: (payload) => setOuvrageDetails([payload]),
      enabled: !!ouvrageSelected[0]?.id,
    }
  );

  useEffect(() => {
    if (ouvrageSelected[0]?.id) {
      ouvrage_refetch();
    }
  }, [ouvrageSelected]);

  const openOuvrageCreation = () => {
    setOuvrageDetailsStatus("create");
  };

  // useEffect(() => {
  //   debouncedSearch({ refetch_ouvrage_professions });
  // }, [wordEntered, refetch_ouvrage_professions]);

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);

    if (searchWord === "") {
      clearInput();
    }
  };
  const clearInput = () => {
    setWordEntered("");
  };

  return (
    <Container>
      <div style={{ width: "33%" }}>
        <TitleContainer label={`${determinePluralWording()} disponibles`}>
          <AddButton
            onClick={openOuvrageCreation}
            disabled={ouvrageDetailsStatus === "create"}
            label={determineSingularWording()}
          />
        </TitleContainer>
        <StyledSearch
          allowClear
          placeholder={`Rechercher par ${determineSingularWording().toLowerCase()}`}
          value={wordEntered}
          onChange={handleFilter}
        />
        <OuvrageArray
          ouvrages={ouvrages}
          setOuvrages={setOuvrages}
          wordEntered={wordEntered}
          categories={categories}
          token={token}
          entityId={entityId}
          configuration={configuration}
          setOuvrageChildId={setOuvrageChildId}
          entityWorkId={entityWorkId}
          setOuvrageSelected={setOuvrageSelected}
          ouvrageSelected={ouvrageSelected}
          setOuvrageDetailsStatus={setOuvrageDetailsStatus}
          ouvrageDetailsStatus={ouvrageDetailsStatus}
          setOuvrageDetails={setOuvrageDetails}
          setOuvrageDynamicLabel={setOuvrageDynamicLabel}
          ouvrageProfessionsQueryKey={ouvrageProfessionsQueryKey}
          group={group}
        />
      </div>
      <Separator />
      <div style={{ width: "33%" }}>
        <TitleContainer label={`${determineSingularWording()} détails`} />
        <OuvrageDetails
          ouvrageSelected={ouvrageSelected}
          ouvrageDetails={ouvrageDetails}
          setOuvrageDetails={setOuvrageDetails}
          setOuvrageSelected={setOuvrageSelected}
          token={token}
          vocabulary={vocabulary}
          ouvrageDetailsStatus={ouvrageDetailsStatus}
          setOuvrageDetailsStatus={setOuvrageDetailsStatus}
          configuration={configuration}
          ouvrageChildId={ouvrageChildId}
          setOuvrageChildId={setOuvrageChildId}
          setOuvrageDynamicLabel={setOuvrageDynamicLabel}
          defaultText={` Sélectionnez ou créez un ${determineSingularWording().toLowerCase()} pour afficher les
          détails.`}
        />
      </div>
      <Separator />
      <div style={{ width: "33%" }}>
        <TitleContainer label={ouvrageDynamicLabel}>
          {(ouvrageDetailsStatus === "create" ||
            ouvrageDetailsStatus === "knot") && (
            <>
              <StyledButton
                label="Annuler"
                onClick={() => setOuvrageDetailsStatus("empty")}
                fontSize="14px"
                btnType="cancel"
              />
              <AddButton
                label="Créer"
                type="submit"
                value="submit"
                fontSize="14px"
                form="ouvrage-form"
              />
            </>
          )}
          <Loading status={statusOuvrage} />
        </TitleContainer>
        <OuvrageUpdate
          ouvrages={ouvrages}
          setOuvrageDynamicLabel={setOuvrageDynamicLabel}
          setOuvrages={setOuvrages}
          ouvrageSelected={ouvrageSelected}
          setOuvrageSelected={setOuvrageSelected}
          token={token}
          ouvrageDetailsStatus={ouvrageDetailsStatus}
          setOuvrageDetailsStatus={setOuvrageDetailsStatus}
          configuration={configuration}
          ouvrageChildId={ouvrageChildId}
          setOuvrageChildId={setOuvrageChildId}
          vocabulary={vocabulary}
          unities={unities}
          faIsValidate={faIsValidate}
          from={from}
          entityWorkId={entityWorkId}
          entityId={entityId}
          faId={faId}
          ouvrageDetails={ouvrageDetails}
          setStatusOuvrage={setStatusOuvrage}
          categories={categories}
          setOuvrageDetails={setOuvrageDetails}
          group={group}
        />
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  padding-bottom: 10px;
  height: 100%;
`;

const StyledButton = styled(Button)`
  margin-right: 10px;
`;

const StyledSearch = styled(Search)`
  margin: 0 0 10px;
`;

export default Ouvrages;
