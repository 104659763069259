/* eslint-disable react/no-unstable-nested-components */
import { StopOutlined } from "@ant-design/icons";
import { Checkbox } from "antd";
import React, { useCallback, useRef, useState } from "react";
import { DndProvider, useDrop } from "react-dnd";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import { ShowDeleteConfirm } from "../../../Admin-cf/confirmModal";
import Collapse from "../../../react-ui/Collapse";
import Delete from "../../../react-ui/Icons/Delete";
import Panel from "../../../react-ui/Panel";
import { getData, postData } from "../../../request/instance";
import { useStore } from "../../../store";
import TableContainer from "../../CostingMovementsCarrycots/TableContainer";
import { ItemTypes } from "../../itemTypes";
import AssociatedItems from "./AssociatedItems";
import Carrycot from "./formatZone/carrycot";
import Cipher from "./formatZone/Cipher";
import Difficulty from "./formatZone/Difficulty";
import Estimate from "./formatZone/Estimate";
import FinalQuantity from "./formatZone/final_quantity";
import Increase from "./formatZone/increase";
import OuvrageDetailsTable from "./OuvrageDetailsTable";
import DraggableBodyRow from "../../../react-ui/DraggableRow";
import { estimateQuantityRule } from "../../../constants/rules";
import PreloadingItem from "./utils/PreloadingItem";
import CustomIconExpandTable from "./utils/CustomIconExpandTable";
import TvaSelect from "./formatZone/TvaSelect";
import Loader from "../../../react-ui/Loader";
import Option from "../../../react-ui/Icons/Option";
// partialZone props contains global informations about the zone (like name, id, etc...)
// zone state contains materials and prestas opportunities linked to this zone

const selector = (state) => ({
  zoneOpenData: state.zoneOpenData,
  setZoneOpen: state.setZoneOpen,
  formToken: state.formToken,
  isUsingMinutes: state.isUsingMinutes,
  warnings: state.warnings,
  setDesableLoadingOuvrageTree: state.setDesableLoadingOuvrageTree,
});

function Zone({
  partialZone,
  isCollapsed,
  genExtra,
  faId,
  id_opportunity,
  difficulties,
  carrycots,
  majorations,
  formatZoneData,
  opportunityType,
  configuration,
  vocabulary,
  tva_professions,
  showColTVA,
  from,
}) {
  const {
    setZoneOpen,
    formToken,
    isUsingMinutes,
    warnings,
    setDesableLoadingOuvrageTree,
  } = useStore(selector);
  const queryClient = useQueryClient();
  const [data, setData] = useState([]);
  const updateFromPosition = useRef(null);

  const handleMutationResponse = ({ recordType, mutaType = "update" }) => ({
    onMutate: (value) => {
      const isPositionMutation = updateFromPosition.current;
      if (isPositionMutation) updateFromPosition.current = false;
      if (mutaType === "create") return { isPositionMutation };

      const id = parseInt(value.id, 10);
      const dataIndex = data.findIndex((el) => el.id === id);
      const oldData = { ...data[dataIndex] };
      if (!isPositionMutation)
        queryClient.invalidateQueries(["Zone", data[0].zone_id]);
      return {
        dataIndex,
        oldData,
        isPositionMutation,
      };
    },
    onSuccess: async (payload, _, context) => {
      queryClient.invalidateQueries(["Zone", partialZone?.id]);
      if (_?.ouvrage_parent_id) {
        await setDesableLoadingOuvrageTree(true);
        await queryClient.refetchQueries([
          "Ouvrage",
          {
            id: _?.ouvrage_parent_id,
          },
        ]);
        await setDesableLoadingOuvrageTree(false);
      }
      if (context?.isPositionMutation) return;
      if (mutaType === "create") {
        queryClient.invalidateQueries("Warnings");
        queryClient.invalidateQueries("TvaProfessionsList");
        const newItem = formatZoneData({
          value: payload,
          recordType,
        });
        // When zone has no value, update zones query to disable deletion of this zone
        if (data.length === 0) {
          queryClient.invalidateQueries("Zones");
        }
        setData([newItem, ...data]);
        return;
      }

      const { dataIndex } = context;
      const newData = [...data];
      if (mutaType === "delete") {
        queryClient.invalidateQueries("Warnings");
        newData.splice(dataIndex, 1);
        // When zone has no value, update zones query to permit deletion of this zone
        if (newData.length === 0) {
          queryClient.invalidateQueries("Zones");
        }
        setData(newData);
        return;
      }
      newData[dataIndex] = formatZoneData({
        value: payload,
        recordType,
      });
      setData(newData);
    },
    onError: async (_, __, context) => {
      const newData = [...data];

      if (mutaType !== "update") {
        const newDataWithoutLoading = await newData.filter(
          (item) => !item?.isLoading
        );
        setData(newDataWithoutLoading);
        return;
      }
      const { dataIndex, oldData } = context;
      newData[dataIndex] = oldData;
      setData(newData);
    },
  });

  //  const { data: tva_professions } = useQuery("TvaProfessionsList", () =>
  //    getData(formToken, `/list_tva_opportunity`)
  //  );

  const { data: zone, isLoading } = useQuery(
    ["Zone", partialZone?.id],
    () =>
      getData(formToken, `/zone/show_by_id/${partialZone?.id}?from=${from}`),
    {
      enabled: from !== "configuration",
      onSuccess: (payload) => {
        const materialOpportunities = payload.material_opportunities.map((el) =>
          formatZoneData({ value: el, zone, recordType: "material" })
        );
        const prestationOpportunities = payload.prestation_opportunities.map(
          (el) => formatZoneData({ value: el, zone, recordType: "prestation" })
        );
        const ouvrageOpportunities = payload.ouvrage_opportunities.map((el) =>
          formatZoneData({ value: el, zone, recordType: "ouvrage" })
        );
        const newData = [
          ...materialOpportunities,
          ...prestationOpportunities,
          ...ouvrageOpportunities,
        ];
        newData.sort((a, b) => {
          if (a.position === undefined || a.position === null) return 1;
          if (b.position === undefined || b.position === null) return -1;
          if (a.position > b.position) return 1;
          return -1;
        });
        setData(newData);
      },
    }
  );

  const { mutate: createOuvrageOpportunity } = useMutation(
    (todo) => postData(formToken, "/ouvrage_opportunity/create", todo),
    handleMutationResponse({ recordType: "ouvrage", mutaType: "create" })
  );

  const { mutate: createMaterialOpportunity } = useMutation(
    (todo) =>
      postData(formToken, `/material_opportunity/create?from=${from}`, todo),
    handleMutationResponse({ recordType: "material", mutaType: "create" })
  );

  const { mutate: createMaterialOppQuotation } = useMutation(
    (todo) =>
      postData(
        formToken,
        "/material_opportunity/create_for_referencing_or_quotation",
        todo
      ),
    handleMutationResponse({ recordType: "material", mutaType: "create" })
  );

  const { mutate: createPrestationOpportunity } = useMutation(
    (todo) =>
      postData(formToken, `/prestation_opportunity/create?from=${from}`, todo),
    handleMutationResponse({ recordType: "prestation", mutaType: "create" })
  );

  const { mutate: createPrestationOppQuotation } = useMutation(
    (todo) =>
      postData(formToken, "/prestation_opportunity/create_for_quotation", todo),
    handleMutationResponse({ recordType: "prestation", mutaType: "create" })
  );

  const { mutate: updatePrestationOpportunity } = useMutation(
    (todo) =>
      postData(formToken, `/prestation_opportunity/update?from=${from}`, todo),
    handleMutationResponse({ recordType: "prestation" })
  );

  const {
    mutate: updateMaterialOpportunity,
    variables,
    isLoading: isUpdatingMaterial,
  } = useMutation(
    (todo) => postData(formToken, "/material_opportunity/update", todo),
    handleMutationResponse({ recordType: "material" })
  );

  const { mutate: updateOuvrageOpportunity } = useMutation(
    (todo) => postData(formToken, "/ouvrage_opportunity/update", todo),
    handleMutationResponse({ recordType: "ouvrage" })
  );

  const { mutate: deleteMaterialOpportunity } = useMutation(
    (todoData) => postData(formToken, `/material_opportunity/delete`, todoData),
    handleMutationResponse({ recordType: "material", mutaType: "delete" })
  );

  const { mutate: deletePrestationOpportunity } = useMutation(
    (todoData) =>
      postData(formToken, `/prestation_opportunity/delete`, todoData),
    handleMutationResponse({ recordType: "prestation", mutaType: "delete" })
  );
  const { mutate: deleteOuvrageOpportunity } = useMutation(
    (todoData) => postData(formToken, `/ouvrage_opportunity/delete`, todoData),
    handleMutationResponse({ recordType: "ouvrage", mutaType: "delete" })
  );

  const deleteItemInZone = useCallback(
    (id, value) => {
      if (value?.recordType === "ouvrage" || value?.ElementType === "O") {
        deleteOuvrageOpportunity({
          id,
          ...(value?.parent_ouvrage_id && {
            ouvrage_parent_id: value?.parent_ouvrage_id,
          }),
        });
      } else if ("prestation_profession_id" in value) {
        deletePrestationOpportunity({
          opportunity_id: id_opportunity,
          id,
          ...(value?.ouvrage_parent_id && {
            ouvrage_parent_id: value?.ouvrage_parent_id,
          }),
        });
      } else {
        deleteMaterialOpportunity({
          opportunity_id: id_opportunity,
          id,
          ...(value?.ouvrage_parent_id && {
            ouvrage_parent_id: value?.ouvrage_parent_id,
          }),
        });
      }
    },
    [
      deleteMaterialOpportunity,
      deletePrestationOpportunity,
      id_opportunity,
      deleteOuvrageOpportunity,
    ]
  );

  const itemSelector = useCallback(
    (type) => {
      if (type === "prestation") {
        return `${configuration.general.prestation_icon} mr-1`;
      }
      if (type === "material") {
        return `${configuration.general.product_icon} mr-1`;
      }
      return `${configuration.ouvrage_option.icon} mr-1`;
    },
    [
      configuration.general.prestation_icon,
      configuration.general.product_icon,
      configuration.ouvrage_option.icon,
    ]
  );

  const lastMaterialUpdatedId = variables?.id;
  const lastMaterialUpdatedQuantity = variables?.quantity;

  const zoneArrayColumns = useCallback(
    ({
      updateMaterial,
      updatePrestation,
      updateOuvrage,
      deleteItem,
      isParent = false,
      parentQuantity = 1,
    }) =>
      [
        {
          title: "Intitulé",
          dataIndex: "entitled",
          key: "entitled",
          className: "name-column",
          onCell: () => ({
            style: { ...(isParent && { cursor: "move" }) },
          }),
          render: (name, record) => (
            <div>
              <i className={itemSelector(record.recordType)} /> {name}
            </div>
          ),
        },
        {
          title: "S-T",
          dataIndex: "subcontracting",
          key: "subcontracting",
          width: 30,
          align: "center",
          render: (subcontracting, record) => {
            return record.recordType === "prestation" ? (
              <Checkbox defaultChecked={record.subcontracting} disabled />
            ) : null;
          },
          hidden:
            !configuration.general.subcontracting ||
            !configuration.costing.page_1.table_col_subcontracting,
        },
        {
          title: "Qté",
          hidden: !configuration.costing.page_1.table_col_quantity,
          children: [
            {
              title: "Estimée",
              dataIndex: "estimate",
              key: "estimate",
              width: 65,
              render: (_, record) =>
                !record.is_template ? (
                  <Estimate
                    parentQuantity={parentQuantity}
                    value={record}
                    updatePrestationOpportunity={updatePrestation}
                    updateMaterialOpportunity={updateMaterial}
                    updateOuvrageOpportunity={updateOuvrage}
                    id_opportunity={id_opportunity}
                    zone_id={partialZone?.id}
                    isUpdatingMaterialQuantity={
                      isUpdatingMaterial &&
                      lastMaterialUpdatedId === record.id &&
                      typeof lastMaterialUpdatedQuantity === "number"
                    }
                  />
                ) : null,
            },
            {
              title: "Chiffrée",
              dataIndex: "cipher",
              key: "cipher",
              width: 75,
              render: (_, record) => (
                <Cipher
                  value={record}
                  id_opportunity={id_opportunity}
                  updatePrestationOpportunity={updatePrestation}
                  updateMaterialOpportunity={updateMaterial}
                  updateOuvrageOpportunity={updateOuvrage}
                />
              ),
            },
          ],
        },
        {
          title: `${vocabulary.difficulty_label}`,
          dataIndex: "difficulty",
          key: "difficulty",
          hidden:
            faId == null
              ? !configuration.costing.page_1.table_col_difficulty
              : !configuration.costing.page_1.table_col_fa_difficulty,
          width: 150,
          render: (_, record) =>
            record.recordType === "prestation" &&
            !record.is_package &&
            !record.is_from_fa && (
              <Difficulty
                updatePrestationOpportunity={updatePrestation}
                value={record}
                id_opportunity={id_opportunity}
                zone={zone}
                difficulties={difficulties}
                from={from}
              />
            ),
        },
        {
          title: `Durée`,
          dataIndex: "finalquantity",
          key: "finalquantity",
          width: 75,
          hidden: !configuration.costing.page_1.table_col_final_quantity,
          render: (_, record) => <FinalQuantity value={record} />,
        },
        {
          title: "Nacelle",
          dataIndex: "nacelle",
          key: "nacelle",
          width: 115,
          render: (_, record) => (
            <Carrycot
              value={record}
              carrycots={carrycots}
              updatePrestationOpportunity={updatePrestation}
              from={from}
            />
          ),
          hidden:
            faId == null
              ? !configuration.admin.carrycot_tab.visible
              : !configuration.costing.page_1.table_col_fa_carrycot,
        },
        {
          title: "Majoration",
          dataIndex: "increase",
          key: "increase",
          width: 115,
          hidden:
            faId == null
              ? !configuration.general.majoration
              : !configuration.costing.page_1.table_col_fa_majoration,
          render: (_, record) =>
            !record.is_from_fa && (
              <Increase
                value={record}
                zone={zone}
                majorations={majorations}
                updatePrestationOpportunity={updatePrestation}
                from={from}
              />
            ),
        },
        {
          title: "TVA",
          width: 115,
          hidden: !showColTVA,
          render: (_, record) => (
            <TvaSelect
              value={record}
              tva_options={tva_professions}
              updatePrestationOpportunity={updatePrestation}
              updateMaterialOpportunity={updateMaterial}
            />
          ),
        },

        {
          title: "",
          dataIndex: "delete",
          key: "delete",
          align: "center",
          width: 30,
          render: (_, record) => {
            if (record.is_template) return <StopOutlined />;
            if (record.id) {
              return (
                <Delete
                  onClick={() =>
                    ShowDeleteConfirm(
                      record.name,
                      record.id,
                      deleteItem,
                      record
                    )
                  }
                />
              );
            }
            return null;
          },
        },
      ].filter((column) => !column.hidden),
    [
      configuration.general.subcontracting,
      configuration.costing.page_1.table_col_subcontracting,
      configuration.general.majoration,
      configuration.costing.page_1.table_col_quantity,
      configuration.costing.page_1.table_col_difficulty,
      configuration.costing.page_1.table_col_fa_difficulty,
      configuration.costing.page_1.table_col_final_quantity,
      configuration.costing.page_1.table_col_fa_carrycot,
      configuration.costing.page_1.table_col_fa_majoration,
      configuration.admin.carrycot_tab.visible,
      vocabulary.difficulty_label,
      faId,
      isUsingMinutes,
      itemSelector,
      id_opportunity,
      partialZone?.id,
      zone,
      difficulties,
      carrycots,
      majorations,
      showColTVA,
      isUpdatingMaterial,
      lastMaterialUpdatedId,
      lastMaterialUpdatedQuantity,
    ]
  );
  const isCanDrop = (monitor) => {
    if (
      monitor.getItem()?.flag === "ouvrages" &&
      monitor.getItem()?.element.group === true
    ) {
      return true;
    }
    return monitor.didDrop();
  };

  const onDrop = useCallback(
    ({ item, monitor }) => {
      const didDrop = isCanDrop(monitor);
      if (didDrop) return;
      if (item.flag === "materials") {
        if (faId && item.element.is_from_fa) {
          PreloadingItem({
            setData,
            data,
            recordType: "material",
            zone_id: zone?.id,
            name: item.element.name,
          });
          createMaterialOppQuotation({
            opportunity_id: id_opportunity,
            material_profession_id: item.element.material_profession_id,
            zone_id: zone?.id,
            material_framework_agreement_id: item.element.id,
          });
        } else {
          PreloadingItem({
            setData,
            data,
            recordType: "material",
            zone_id: zone?.id,
            name: item.element.name,
            is_parent: true,
            reference_code: item.element.reference_code,
            reference_constructor: item.element.reference_constructor,
            unity_profession_id: item.element.unity_profession_id,
            manufacturer_id: item.element.manufacturer_id,
            maintenance: opportunityType === "maintenance",
          });
          createMaterialOpportunity({
            category_profession_id: item.element.category_profession_id,
            material_profession_id: item.element.id,
            opportunity_id: id_opportunity,
            name: item.element.name,
            description: item.element.description,
            margin_rate: item.element.margin_rate,
            cost_price_cents: item.element.cost_price_cents,
            material_position: null,
            selling_price_cents:
              (item.element.margin_rate / 100 + 1) *
              item.element.cost_price_cents,
            quantity: 0,
            zone_id: zone?.id,
            is_parent: true,
            reference_code: item.element.reference_code,
            reference_constructor: item.element.reference_constructor,
            unity_profession_id: item.element.unity_profession_id,
            manufacturer_id: item.element.manufacturer_id,
            maintenance:
              zone?.maintenance == null
                ? opportunityType === "maintenance"
                : zone.maintenance,
            drive_link: item.element.drive_link,
            carrycot_tpl_opportunity_id: zone.carrycot_tpl_opportunity_id,
            majoration_opportunity_id: !item.element.is_package
              ? zone.majoration_opportunity_id
              : null,
          });
        }
      } else if (item.flag === "prestations") {
        if (faId && item.element.is_from_fa) {
          PreloadingItem({
            setData,
            data,
            recordType: "prestation",
            zone_id: zone?.id,
            name: item.element.name,
          });
          createPrestationOppQuotation({
            opportunity_id: id_opportunity,
            prestation_profession_id: item.element.prestation_profession_id,
            zone_id: zone?.id,
            prestation_framework_agreement_id: item.element.id,
            carrycot_tpl_opportunity_id: zone.carrycot_tpl_opportunity_id,
            is_parent: true,
            is_from_fa: true,
          });
        } else {
          PreloadingItem({
            setData,
            data,
            recordType: "prestation",
            zone_id: zone?.id,
            name: item.element.name,
            reference_code: item.element.reference_code,
            is_parent: true,
            profil_profession_id: item.element.profil_profession_id,
            is_package: item.element.is_package,
            is_from_fa: false,
          });

          createPrestationOpportunity({
            prestation_profession_id: item.element.id,
            category_profession_id: item.element.category_profession_id,
            opportunity_id: Number(id_opportunity),
            difficulty_opportunity_id: !item.element.is_package
              ? zone.difficulty_opportunity_id
              : null,
            majoration_opportunity_id: !item.element.is_package
              ? zone.majoration_opportunity_id
              : null,
            carrycot_tpl_opportunity_id: zone.carrycot_tpl_opportunity_id,
            difficulty_prestation: 0,
            eligible_nacelle: true,
            unity_quantity: 0,
            unity_profession_id: item.element.unity_profession_id,
            name: item.element.name,
            description: item.element.description,
            hourly_rate_cents: item.element.hourly_rate_cents || 0,
            cost_price_cents: item.element.cost_price_cents,
            selling_price_cents: 0,
            margin_rate: item.element.margin_rate,
            subcontracting: item.element.subcontracting,
            maintenance: item.element.maintenance,
            speed: item.element.speed,
            speed_quantity: item.element.speed_quantity,
            estimate_quantity: item.element.quantity,
            zone_id: zone?.id,
            reference_code: item.element.reference_code,
            is_parent: true,
            profil_profession_id: item.element.profil_profession_id,
            is_package: item.element.is_package,
            is_from_fa: false,
            drive_link: item.element.drive_link,
            minutes: item.element.minutes,
          });
        }
      } else if (item.flag === "ouvrages") {
        PreloadingItem({
          setData,
          data,
          recordType: item.flag,
          zone_id: zone?.id,
          name: item.element.name,
        });
        createOuvrageOpportunity({
          batiprix_ouvrage_id: item.element?.Id,
          ouvrage_profession_id: item.element.ouvrage_profession_id,
          opportunity_id: Number(id_opportunity),
          zone_id: zone?.id,
          difficulty_opportunity_id: zone.difficulty_opportunity_id,
          majoration_opportunity_id: zone.majoration_opportunity_id,
          carrycot_tpl_opportunity_id: zone.carrycot_tpl_opportunity_id,
        });
      }
    },
    [
      createMaterialOppQuotation,
      createMaterialOpportunity,
      createPrestationOppQuotation,
      createPrestationOpportunity,
      createOuvrageOpportunity,
      faId,
      id_opportunity,
      opportunityType,
      zone,
    ]
  );

  const canIsOver = (monitor) => {
    if (
      monitor.getItem()?.flag === "ouvrages" &&
      monitor.getItem()?.element.group === true
    ) {
      return false;
    }
    return monitor.isOver();
  };

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: ItemTypes.BOX,
      drop: async (item, monitor) =>
        onDrop({
          item,
          zone: partialZone,
          setData,
          zoneData: data,
          monitor,
        }),
      collect: (monitor) => ({
        isOver: canIsOver(monitor),
        canDrop: monitor.canDrop(),
      }),
    }),
    [data, partialZone]
  );

  const expandedRowRender = (parent) => {
    if (parent.recordType === "ouvrage") {
      return (
        <OuvrageDetailsTable
          zone={zone}
          showColTVA={showColTVA}
          parentOuvrage={parent}
          formToken={formToken}
          zoneArrayColumns={zoneArrayColumns}
          updateMaterialOpportunity={updateMaterialOpportunity}
          updatePrestationOpportunity={updatePrestationOpportunity}
          updateOuvrageOpportunity={updateOuvrageOpportunity}
          deleteItemInZone={deleteItemInZone}
          configuration={configuration}
        />
      );
    }
    return (
      <AssociatedItems
        zone={zone}
        faId={faId}
        id_opportunity={id_opportunity}
        parentMaterial={parent}
        formatZoneData={formatZoneData}
        zoneArrayColumns={zoneArrayColumns}
        updateOuvrageOpportunity={updateOuvrageOpportunity}
        configuration={configuration}
        showColTVA={showColTVA}
        from={from}
      />
    );
  };

  let backGroundColor = "";
  const isActive = canDrop && isOver;
  if (isActive) backGroundColor = "green";
  else if (canDrop) backGroundColor = "red";

  const moveRow = useCallback(
    (dragIndex, hoverIndex, { record }) => {
      updateFromPosition.current = true;
      const dragRow = data[dragIndex];
      setData(
        update(data, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
      );
      if (record.recordType === "material")
        updateMaterialOpportunity({ id: record.id, position: hoverIndex });
      if (record.recordType === "prestation")
        updatePrestationOpportunity({ id: record.id, position: hoverIndex });
      if (record.recordType === "ouvrage")
        updateOuvrageOpportunity({ id: record.id, position: hoverIndex });
    },
    [
      data,
      setData,
      updateMaterialOpportunity,
      updatePrestationOpportunity,
      updateOuvrageOpportunity,
    ]
  );

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  const title_zone = (title) => {
    return <div>{title}</div>;
  };

  const getRowClassName = useCallback(
    (record) => {
      if (
        warnings
          ?.filter((w) => w.rule.rule_on === estimateQuantityRule.id)
          .some((w) => {
            const { material_opportunity_parent_id, ouvrage_parent_id } = w;
            if (
              (record.recordType === "material" ||
                record.recordType === "ouvrage") &&
              (record.id === material_opportunity_parent_id ||
                ouvrage_parent_id?.includes(record.id))
            )
              return true;
            return false;
          })
      )
        return "warning-expand-icon";
      return "";
    },
    [warnings]
  );

  const renderZoneTitle = (title, option) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span>{title}</span>
      {option && <Option style={{ marginLeft: 8 }} />}
    </div>
  );

  return (
    <div ref={drop} key={partialZone?.id}>
      <Collapse defaultActiveKey={isCollapsed} onChange={(e) => setZoneOpen(e)}>
        <Panel
          key={partialZone?.id}
          id={`zone-${partialZone?.id}`}
          header={renderZoneTitle(partialZone?.entitled, partialZone?.option)}
          style={{ backgroundColor: backGroundColor }}
          extra={genExtra(partialZone)}
          option={partialZone?.option}
        >
          {isLoading && !zone && <Loader />}
          {!!data && (
            <DndProvider backend={HTML5Backend}>
              <TableContainer
                components={components}
                columns={zoneArrayColumns({
                  updateMaterial: updateMaterialOpportunity,
                  updatePrestation: updatePrestationOpportunity,
                  updateOuvrage: updateOuvrageOpportunity,
                  deleteItem: deleteItemInZone,
                  isParent: true,
                })}
                data={data}
                style={{ wordWrap: "break-word", wordBreak: "break-word" }}
                rowClassName={getRowClassName}
                expandable={{
                  expandedRowRender,
                  columnWidth: 1,
                  expandIcon: (props) => (
                    <CustomIconExpandTable
                      expanded={props.expanded}
                      record={props.record}
                      onExpand={props.onExpand}
                    />
                  ),
                  rowExpandable: (record) => {
                    if (record.recordType === "prestation") return false;
                    return true;
                  },
                }}
                pagination={false}
                isExpandTableLinked
                tr={2}
                onRow={(record, index) => {
                  const attr = {
                    index,
                    moveRow,
                    itemId: `${record.recordType}-${record.id}`,
                    record,
                    dragKey: "entitled",
                    type: `zone-${zone?.id}`,
                  };
                  return attr;
                }}
              />
            </DndProvider>
          )}
        </Panel>
      </Collapse>
    </div>
  );
}

export default Zone;
