import React, { useMemo } from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import { getData } from "../../request/instance";
import { useStore } from "../../store";
import { getUrlParams } from "../../utils";
import RightBreadcrumb from "../../breadcrumb-right/RightBreadcrumb";

const selector = (state) => ({
  step: state.step,
  formToken: state.formToken,
  setStep: state.setStep,
});

function GuardingBreadcrumb({
  isBuyer,
  opportunity,
  offer_name,
  vocabularyApplication,
  site_typologies,
  supervisor,
  documents_disabled,
}) {
  const { step, formToken, setStep } = useStore(selector);
  const urlParams = getUrlParams();
  useQuery(
    ["OpportunityStep", { opportunityId: opportunity.id }],
    () =>
      getData(formToken, `/opportunity_step?opportunity_id=${opportunity.id}`),
    {
      refetchOnWindowFocus: false,
      onSuccess: (payload) => {
        setStep(payload.step);
      },
    }
  );

  const navArrays = useMemo(() => {
    const arr = [
      {
        name: `${vocabularyApplication.breadcrumb.offer} (${offer_name})`,
        link: `/offers/${opportunity.offer_id}`,
      },
      {
        name: `${vocabularyApplication.breadcrumb.opportunity} (${opportunity.opportunity_name})`,
        key: "edit",
        link: `/opportunities/${opportunity.id}/edit`,
      },
      {
        name: `Dispositif`,
        key: "work_positions",
        link: `/opportunities/${opportunity.id}/work_positions`,
      },
      {
        name: `Personnel`,
        key: "agents",
        link: `/opportunities/${opportunity.id}/agents`,
      },
      {
        name: `Tenues`,
        link: `/opportunities/${opportunity.id}/instantiate_outfits`,
      },
      {
        name: `Formations`,
        link: `/opportunities/${opportunity.id}/instantiate_formations`,
      },
      {
        name: `Matériels`,
        link: `/opportunities/${opportunity.id}/instantiate_materials`,
      },
      {
        name: `Synthèse financière`,
        link: `/opportunities/${opportunity.id}/summary`,
      },
    ];
    arr.splice(step + 1);
    return arr;
  }, [offer_name, opportunity, step]);

  return (
    <div className="d-flex align-items-center font-18">
      {navArrays.map((el, idx) =>
        el.name === "Synthèse financière" ? (
          isBuyer &&
          (opportunity.status === "prospect" ||
            opportunity.status === "pending") ? (
            <React.Fragment key={el.name}>
              <Element href={el.link} active={el.key === urlParams} />
              {idx !== navArrays.length - 1 && (
                <i className="fa-duotone fa-angle-right breadcrumb-separator" />
              )}
            </React.Fragment>
          ) : (
            <React.Fragment key={el.name}>
              <Element href={el.link} active={el.key === urlParams}>
                {el.name}
              </Element>
              {idx !== navArrays.length - 1 && (
                <i className="fa-duotone fa-angle-right breadcrumb-separator" />
              )}
            </React.Fragment>
          )
        ) : (
          <React.Fragment key={el.name}>
            <Element href={el.link} active={el.key === urlParams}>
              {el.name}
            </Element>
            {idx !== navArrays.length - 1 && (
              <i className="fa-duotone fa-angle-right breadcrumb-separator" />
            )}
          </React.Fragment>
        )
      )}
      <RightBreadcrumb
        formToken={formToken}
        opportunity_id={opportunity.id}
        opportunity_name={opportunity.opportunity_name}
        offer_name={offer_name}
        response_type={opportunity.response_type}
        work={opportunity.work}
        offer_id={opportunity.offer_id}
        without_warnings
        site_typologies={site_typologies}
        supervisor={supervisor}
        opportunity_chrono={opportunity.chrono}
        opportunity_work="guarding"
        documents_disabled={documents_disabled}
      />
    </div>
  );
}

const Element = styled.a`
  color: #585858;
  font-size: 12px;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 10px;
  margin-left: 0px;
  cursor: pointer;
  margin-right: 0px;
  font-weight: ${({ active }) => (active ? "bold" : "normal")};
  font-size: ${({ active }) => (active ? "14px !important" : "12px")};

  &:hover {
    color: ${({ active }) => (active ? "#585858" : "red")};
  }
`;

export default GuardingBreadcrumb;
