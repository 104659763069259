import { Tabs } from "antd";
import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { useStore } from "../store";
import { getUrlParams } from "../utils";
import Nacelles from "./Carrycots/index";
import Categories from "./Categories";
import Distributors from "./Distributors";
import Families from "./Families";
import FrameworkAgreements from "./FrameworkAgreements";
import GeneralParameters from "./GeneralParameters";
import TvaArray from "./GeneralParameters/TvaArray";
import Groupings from "./Groupings";
import Manufacturer from "./Manufacturer";
import Material from "./Materials/index";
import Shift from "./Movements/index";
import Ouvrages from "./Ouvrages";
import Prestation from "./Prestations/index";
import Profil from "./Profils/index";
import RentalOffers from "./RentalOffers";
import UserInterface from "./UserInterfaces";
import ZoneTemplates from "./ZoneTemplates";

const selector = (state) => ({
  setApiProvider: state.setApiProvider,
  configuration: state.configurations,
  setConfiguration: state.setConfigurations,
  vocabulary: state.vocabulary,
});

function AdminCf(props) {
  const { setApiProvider, setConfiguration, configuration, vocabulary } =
    useStore(selector);

  const [selectedFA, setSelectedFA] = useState(); // Used to keep track on change made on FA
  const [initialFA, setInitialFA] = useState(); // Used to get default value of FA Form (and to update it in case of update error)
  const [selectedRental, setSelectedRental] = useState(null);
  const [selectedTab, setSelectedTab] = useState("products");
  const handleTabClick = (key) => {
    if (key === "frameworkAgreement") setSelectedFA();
    if (key === "rentalOffers") setSelectedRental(null);
    setSelectedTab(key);
  };

  useEffect(() => {
    setApiProvider(props?.api_providers);
  }, [props?.api_providers]);

  useEffect(() => {
    if (props?.configuration !== null) {
      setConfiguration(props?.configuration);
    }
  }, [props?.configuration]);

  const getTabName = (keyVocabulary, keyIcon, keyTab, iconName = null) => (
    <TabLabelContainer>
      <i className={iconName ?? props.configuration.general[keyIcon]} />
      <TabName $show={keyTab === selectedTab}>
        {props.vocabulary[keyVocabulary]}
      </TabName>
      <span className="tooltiptext">{props.vocabulary[keyVocabulary]}</span>
    </TabLabelContainer>
  );

  const tva_management_tab = () => {
    return (
      configuration?.admin?.tva_management_tab.visible && {
        label: getTabName(
          "tva_management",
          "tva_management_icon",
          "tva_management"
        ),
        key: "tva_management",
        children: (
          <TvaArray
            token={props.token}
            company_work_id={props.company_work_id}
            configuration={props.configuration}
            vocabulary={props.vocabulary}
          />
        ),
      }
    );
  };

  const deplacement_tab = () => {
    return (
      configuration?.admin?.movement_tab.visible && {
        label: getTabName("movements", "movement_icon", "movements"),
        key: "movements",
        children: (
          <Shift
            token={props.token}
            configuration={props.configuration}
            vocabulary={props.vocabulary}
          />
        ),
      }
    );
  };
  const frameworkAgreement_tab = () => {
    return (
      configuration?.admin?.framework_agreement_tab.visible && {
        label: getTabName(
          "framework_agreements",
          "framework_agreement_icon",
          "framework_agreements"
        ),
        key: "framework_agreements",
        children: (
          <FrameworkAgreements
            token={props.token}
            entityId={props.entity_id}
            selectedFA={selectedFA}
            setSelectedFA={setSelectedFA}
            initialFA={initialFA}
            setInitialFA={setInitialFA}
            configuration={props.configuration}
            vocabulary={props.vocabulary}
            entityWorkId={getUrlParams()}
          />
        ),
      }
    );
  };

  const manufacturer_tab = () => {
    return (
      configuration?.admin?.maker_tab.visible && {
        label: getTabName(
          "manufacturers",
          "manufacturer_icon",
          "manufacturers"
        ),
        key: "manufacturers",
        children: (
          <Manufacturer
            token={props.token}
            configuration={props.configuration}
            vocabulary={props.vocabulary}
          />
        ),
      }
    );
  };

  const distributors = () => {
    return (
      configuration?.admin?.distributor_tab.visible && {
        label: getTabName("distributors", "distributor_icon", "distributors"),
        key: "distributors",
        children: (
          <Distributors
            token={props.token}
            configuration={props.configuration}
            vocabulary={props.vocabulary}
          />
        ),
      }
    );
  };

  const nacelle_tab = () => {
    return (
      configuration?.admin?.carrycot_tab.visible && {
        label: getTabName("carrycots", "carrycot_icon", "carrycots"),
        key: "carrycots",
        children: (
          <Nacelles
            token={props.token}
            configuration={props.configuration}
            vocabulary={props.vocabulary}
          />
        ),
      }
    );
  };

  const profils_tab = () => {
    return (
      configuration?.admin?.profil_tab.visible && {
        label: getTabName("profils", "profil_icon", "profils"),
        key: "profils",
        children: (
          <Profil
            token={props.token}
            entity_work_name={props.entity_work_name}
            configuration={props.configuration}
            vocabulary={props.vocabulary}
            from="admin"
          />
        ),
      }
    );
  };

  const zone_template_tab = () => {
    return (
      configuration?.admin?.zone_template_tab.visible && {
        label: getTabName(
          "zone_templates",
          "zone_template_icon",
          "zone_templates"
        ),
        key: "zone_templates",
        children: (
          <ZoneTemplates
            token={props.token}
            entityId={props.entity_id}
            management_special_TVA={props.management_special_TVA}
          />
        ),
      }
    );
  };

  const ouvrage_tab = () => {
    return (
      configuration?.admin?.ouvrage_tab.visible && {
        label: getTabName("ouvrages", "ouvrage_icon", "ouvrages"),
        key: "ouvrages",
        children: (
          <Ouvrages
            token={props.token}
            configuration={props.configuration}
            vocabulary={props.vocabulary}
            entityId={props.entity_id}
            group={false}
          />
        ),
      }
    );
  };

  const group_tab = () => {
    return (
      configuration?.admin?.group_tab.visible && {
        label: getTabName("groups", "group_icon", "groups"),
        key: "groups",
        children: (
          <Ouvrages
            token={props.token}
            configuration={props.configuration}
            vocabulary={props.vocabulary}
            entityId={props.entity_id}
            group
          />
        ),
      }
    );
  };

  const grouping_tab = () => {
    return (
      configuration?.admin?.grouping_tab.visible && {
        label: getTabName("groupings", "grouping_icon", "groupings"),
        key: "groupings",
        children: (
          <Groupings
            token={props.token}
            company_work_id={props.company_work_id}
            configuration={props.configuration}
            vocabulary={props.vocabulary}
            entityId={props.entity_id}
            group
          />
        ),
      }
    );
  };

  const user_interface_tab = () => {
    return (
      props.entity_work_name !== "guarding" && {
        label: getTabName(
          "interfaces",
          "interfaces_icon",
          "interfaces",
          "fa-duotone fa-display-code"
        ),
        key: "interfaces",
        children: (
          <UserInterface
            props={props}
            token={props.token}
            entity_work_name={props.entity_work_name}
            configuration={props.configuration}
            setConfiguration={setConfiguration}
            vocabulary={props.vocabulary}
            management_special_TVA={props.management_special_TVA}
          />
        ),
      }
    );
  };

  const items = [
    {
      label: getTabName("products", "product_icon", "products"),
      key: "products",
      children: (
        <Material
          token={props.token}
          entity_work_name={props.entity_work_name}
          configuration={props.configuration}
          vocabulary={props.vocabulary}
          use_special_tva={props.use_special_tva}
          round_type_list={props.round_type_list}
        />
      ),
    },
    {
      label: getTabName("prestations", "prestation_icon", "prestations"),
      key: "prestations",
      children: (
        <Prestation
          entity_work_name={props.entity_work_name}
          configuration={props.configuration}
          vocabulary={props.vocabulary}
          defaultMinutes={props.minutes}
          use_special_tva={props.use_special_tva}
        />
      ),
    },
    group_tab(),
    ouvrage_tab(),
    zone_template_tab(),
    {
      label: getTabName("families", "family_icon", "families"),
      key: "families",
      children: (
        <Families
          entity_work_name={props.entity_work_name}
          configuration={props.configuration}
          vocabulary={props.vocabulary}
        />
      ),
    },
    grouping_tab(),
    nacelle_tab(),
    deplacement_tab(),
    profils_tab(),
    manufacturer_tab(),
    distributors(),
    {
      label: getTabName("categories", "category_icon", "categories"),
      key: "categories",
      children: (
        <Categories
          token={props.token}
          entity_work_name={props.entity_work_name}
          configuration={props.configuration}
          vocabulary={props.vocabulary}
          use_special_tva={props.use_special_tva}
        />
      ),
    },
    configuration?.general?.renting && {
      label: getTabName("rentings", "renting_icon", "rentings"),
      key: "rentings",
      children: (
        <RentalOffers
          entityWorkId={getUrlParams()}
          companyWorkId={props.company_work_id}
          selectedRental={selectedRental}
          setSelectedRental={setSelectedRental}
          configuration={props.configuration}
          vocabulary={props.vocabulary}
        />
      ),
    },
    frameworkAgreement_tab(),
    tva_management_tab(),
    {
      label: getTabName(
        "general_parameters",
        "general_parameter_icon",
        "general_parameters"
      ),
      key: "general_parameters",
      children: (
        <GeneralParameters
          token={props.token}
          entity_work_name={props.entity_work_name}
          configuration={props.configuration}
          vocabulary={props.vocabulary}
        />
      ),
    },
    user_interface_tab(),
  ];

  return (
    <StyledTabs
      defaultActiveKey="products"
      type="card"
      style={{ height: "calc(100% - 70px)", overflow: "auto" }}
      destroyInactiveTabPane
      onTabClick={handleTabClick}
      items={items}
      className="admin-cf"
    />
  );
}

const TabLabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  .tooltiptext {
    display: none;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 0px 4px;
    position: absolute;
    z-index: 1;
    font-size: 10px;
    top: -5px;
    left: 0;
  }
`;

const TabName = styled.span`
  display: none;
  ${({ $show }) =>
    $show &&
    css`
      display: block;
    `}
`;

const StyledTabs = styled(Tabs)`
  .ant-tabs-content,
  .ant-tabs-tabpane {
    height: 100%;
  }

  .ant-tabs-tab:not(.ant-tabs-tab-active) {
    &:hover {
      ${TabLabelContainer} {
        .tooltiptext {
          display: block;
        }
      }
    }
  }
`;

export default AdminCf;
