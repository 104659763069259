import React, { useEffect, useMemo, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { formatNumberString } from "../../../../../utils/formatNumberString";
import { quantityTimeConverter } from "../../../../../utils/quantityTimeConverter";
import { stringToFloat } from "../../../../../utils/stringToFloat";
import { estimateQuantityRule } from "../../../../constants/rules";
import Input from "../../../../react-ui/Input";
import { useStore } from "../../../../store";

const selector = (state) => ({
  isUsingMinutes: state.isUsingMinutes,
  warnings: state.warnings,
  setDesableLoadingOuvrageTree: state.setDesableLoadingOuvrageTree,
});

function Cipher({
  value,
  id_opportunity,
  updatePrestationOpportunity,
  updateMaterialOpportunity,
  updateOuvrageOpportunity,
}) {
  const queryClient = useQueryClient();
  const { isUsingMinutes, warnings, setDesableLoadingOuvrageTree } =
    useStore(selector);
  const [inputValue, setInputValue] = useState(
    value.recordType === "prestation" ? value.unity_quantity : value.quantity
  );

  useEffect(() => {
    if (value.recordType === "prestation") setInputValue(value.unity_quantity);
    else setInputValue(value.quantity);
  }, [value.quantity, value.unity_quantity, value.recordType]);

  const getAbbreviation = () => {
    if (value.recordType === "prestation" && !value.is_package) {
      if (value.minutes !== null) {
        return value.minutes ? "min" : "h";
      }
      return isUsingMinutes ? "min" : "h";
    }
    return "u";
  };
  const onBlurCipher = (e) => {
    if (value.is_template) return;
    const data_from_record = { ...value };
    const ouvrage_master_id =
      data_from_record.ouvrage_parent_id || data_from_record.id;
    if ("subcontracting" in value) {
      updatePrestationOpportunity(
        {
          opportunity_id: id_opportunity,
          id: value.id,
          unity_quantity:
            value.is_package || value.speed
              ? stringToFloat(e)
              : quantityTimeConverter({
                  quantity: stringToFloat(e),
                  isUsingMinutes:
                    value.minutes !== null ? value.minutes : isUsingMinutes,
                  toMinutes: false,
                }),
          ...(value?.ouvrage_opportunity_id && {
            ouvrage_opportunity_id: value.ouvrage_opportunity_id,
          }),
        },
        {
          onSuccess: async () => {
            await setDesableLoadingOuvrageTree(true);
            await queryClient.refetchQueries([
              "Ouvrage",
              {
                id: ouvrage_master_id,
              },
            ]);
            await setDesableLoadingOuvrageTree(false);
            queryClient.invalidateQueries("Warnings");
          },
        }
      );
      return;
    }
    if (value.recordType === "material") {
      updateMaterialOpportunity(
        {
          opportunity_id: id_opportunity,
          id: value.id,
          quantity: stringToFloat(e),
          ...(value?.ouvrage_opportunity_id && {
            ouvrage_opportunity_id: value.ouvrage_opportunity_id,
          }),
        },
        {
          onSuccess: async (payload) => {
            await setDesableLoadingOuvrageTree(true);
            await queryClient.refetchQueries([
              "Ouvrage",
              {
                id: ouvrage_master_id,
              },
            ]);
            payload.ouvrage_material_asso_oprts.forEach(async (v) => {
              await queryClient.refetchQueries([
                "Ouvrage",
                {
                  id: v,
                },
              ]);
            });
            await setDesableLoadingOuvrageTree(false);
            queryClient.invalidateQueries("Warnings");
          },
        }
      );
      return;
    }
    if (
      value.recordType === "ouvrage" ||
      value.ElementType === "O" ||
      value.group
    ) {
      // Condition me créer un bug sur la propagation
      // if (
      //   (value.recordType === "material" || value.ElementType === "M") &&
      //   value.children_ouvrages > 0
      // ) {
      updateOuvrageOpportunity(
        {
          opportunity_id: id_opportunity,
          id: value.id,
          quantity: stringToFloat(e),
          ...(value?.parent_ouvrage_id && {
            parent_ouvrage_id: value.parent_ouvrage_id,
          }),
        },
        {
          onSuccess: async () => {
            await setDesableLoadingOuvrageTree(true);
            await queryClient.refetchQueries([
              "Ouvrage",
              {
                id: ouvrage_master_id,
              },
            ]);
            await setDesableLoadingOuvrageTree(false);
            queryClient.invalidateQueries("Warnings");
          },
        }
      );

      // }
    }
  };

  const status = useMemo(() => {
    if (!warnings) return undefined;
    if (
      warnings
        .filter((w) => w.rule.rule_on === estimateQuantityRule.id)
        .some((w) => {
          const { material_opportunity_id, prestation_opportunity_id } = w;

          if (
            value.recordType === "prestation" &&
            value.id === prestation_opportunity_id
          )
            return true;
          if (
            value.recordType === "material" &&
            value.id === material_opportunity_id
          )
            return true;
          return false;
        })
    )
      return "warning";
    return undefined;
  }, [warnings, value]);

  const inputRef = useRef(null);

  return (
    <Input
      id={`${value.recordType}-cipher-${value.id}`}
      fontSize="10px"
      textAlign="right"
      status={status}
      warningInput
      disabled={value.is_template || !value.id}
      value={formatNumberString({
        str: inputValue,
      })}
      onInput={(e) => {
        if (value.is_template || !value.id) return;
        setInputValue(formatNumberString({ str: e.target.value }));
      }}
      onBlur={(e) => {
        onBlurCipher(e.target.value);
      }}
      ref={inputRef}
      suffix={
        value.unity_profession?.abbreviation
          ? value.unity_profession.abbreviation
          : getAbbreviation()
      }
    />
  );
}

export default Cipher;
