import { Space } from "antd";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { GridContainer, StyledCard } from "../styledComponent";
import SwitchComponent from "../SwitchComponent";
import { deepEqual } from "../FilesImport";

export default function ChiffrageConsulting({
  configuration,
  setConfiguration,
}) {
  const [filterChiffrageConsulting, setFilterChiffrageConsulting] = useState({
    subContracting: configuration?.costing.page_1?.table_col_subcontracting,
    quantity: configuration?.costing.page_1?.table_col_quantity,
    difficulty: configuration?.costing.page_1?.table_col_difficulty,
    finalQuantity: configuration?.costing.page_1?.table_col_final_quantity,
    nacelle: configuration?.admin?.carrycot_tab?.visible,
    majoration: configuration?.general?.majoration,
  });

  const itemChiffrageConsulting = [
    {
      initialValue: filterChiffrageConsulting.subContracting,
      labelText: "Sous-traitance",
      name: "subContracting",
    },
    {
      initialValue: filterChiffrageConsulting.quantity,
      labelText: "Quantité",
      name: "quantity",
    },
    {
      initialValue: filterChiffrageConsulting.difficulty,
      labelText: "Difficulté",
      name: "difficulty",
    },
    {
      initialValue: filterChiffrageConsulting.finalQuantity,
      labelText: "Durée",
      name: "finalQuantity",
    },
    {
      initialValue: filterChiffrageConsulting.nacelle,
      labelText: "Nacelle",
      name: "nacelle",
    },
    {
      initialValue: filterChiffrageConsulting.majoration,
      labelText: "Majoration",
      name: "majoration",
    },
  ];

  const [filterChiffrageDevis, setFilterChiffrageDevis] = useState({
    difficulty: configuration?.costing.page_1?.table_col_fa_difficulty,
    nacelle: configuration?.costing.page_1?.table_col_fa_carrycot,
    majoration: configuration?.costing.page_1?.table_col_fa_majoration,
  });

  const itemChiffrageDevis = [
    {
      initialValue: filterChiffrageDevis.difficulty,
      labelText: "Difficulté",
      name: "difficulty",
    },
    {
      initialValue: filterChiffrageDevis.nacelle,
      labelText: "Nacelle",
      name: "nacelle",
    },
    {
      initialValue: filterChiffrageDevis.majoration,
      labelText: "Majoration",
      name: "majoration",
    },
  ];

  useEffect(() => {
    const data_values = {
      ...configuration,
      admin: {
        ...configuration.admin,
        carrycot_tab: {
          ...configuration.admin.carrycot_tab,
          visible: filterChiffrageConsulting.nacelle,
        },
      },
      costing: {
        ...configuration.costing,
        page_1: {
          ...configuration.costing.page_1,
          table_col_subcontracting: filterChiffrageConsulting.subContracting,
          table_col_quantity: filterChiffrageConsulting.quantity,
          table_col_difficulty: filterChiffrageConsulting.difficulty,
          table_col_final_quantity: filterChiffrageConsulting.finalQuantity,
        },
      },
      general: {
        ...configuration.general,
        majoration: filterChiffrageConsulting.majoration,
      },
    };

    if (!deepEqual(data_values, configuration)) {
      setConfiguration(data_values);
    }
  }, [filterChiffrageConsulting]);

  useEffect(() => {
    const data_values = {
      ...configuration,
      costing: {
        ...configuration.costing,
        page_1: {
          ...configuration.costing.page_1,
          table_col_fa_difficulty: filterChiffrageDevis.difficulty,
          table_col_fa_carrycot: filterChiffrageDevis.nacelle,
          table_col_fa_majoration: filterChiffrageDevis.majoration,
        },
      },
    };

    if (!deepEqual(data_values, configuration)) {
      setConfiguration(data_values);
    }
  }, [filterChiffrageDevis]);

  return (
    <Container
      size={16}
      direction="vertical"
      style={{
        width: "100%",
      }}
    >
      <StyledCard
        headStyle={{ backgroundColor: "#0B2239", color: "white" }}
        bodyStyle={{ backgroundColor: "#F5F6F8" }}
        size="small"
        title="Chiffrage consultation"
        style={{
          width: "100%",
        }}
      >
        <GridContainer
          direction="vertical"
          size={16}
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3, auto)",
            gridAutoFlow: "row dense",
          }}
        >
          {itemChiffrageConsulting.map((item) => (
            <SwitchComponent
              key={item.name}
              initialValue={item.initialValue}
              labelText={item.labelText}
              handleChange={(value, name) => {
                setFilterChiffrageConsulting((prev) => ({
                  ...prev,
                  [name]: value,
                }));
              }}
              name={item.name}
            />
          ))}
        </GridContainer>
      </StyledCard>
      {configuration?.general?.framework_agreement && (
        <StyledCard
          headStyle={{ backgroundColor: "#0B2239", color: "white" }}
          bodyStyle={{ backgroundColor: "#F5F6F8" }}
          size="small"
          title="Chiffrage devis accord-cadre"
          style={{
            width: "100%",
          }}
        >
          <GridContainer
            direction="vertical"
            size={16}
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(3, auto)",
              justifyContent: "flex-start",
            }}
          >
            {itemChiffrageDevis.map((item) => (
              <SwitchComponent
                key={item.name}
                initialValue={item.initialValue}
                labelText={item.labelText}
                handleChange={(value, name) => {
                  setFilterChiffrageDevis((prev) => ({
                    ...prev,
                    [name]: value,
                  }));
                }}
                name={item.name}
              />
            ))}
          </GridContainer>
        </StyledCard>
      )}
    </Container>
  );
}

const Container = styled(Space)`
  width: 80%;
  .ant-space-item {
    width: 100%;
  }
  .ant-form-item-label label {
    font-size: 12px;
  }
`;
