import React, { useEffect, useState } from "react";
import { Button, Tooltip, Checkbox } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import styled, { css } from "styled-components";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import useUpdateTodo from "../../../hookQuery/useUpdateTodo";
import { useStore } from "../../../store";
import useCreateTodo from "../../../hookQuery/useCreateTodo";
import useDeleteTodo from "../../../hookQuery/useDeleteTodo";
import Separator from "../../../react-ui/Separator";
import TableContainer from "../../CostingMovementsCarrycots/TableContainer";
import Delete from "../../../react-ui/Icons/Delete";
import Input from "../../../react-ui/Input";
import Select from "../../../react-ui/Select";
import SwapButton from "../../../react-ui/SwapButton";
import { getData, postData } from "../../../request/instance";
import Loader from "../../../react-ui/Loader";

const getColumnsZones = (opportunity_work, configuration, vocabulary) => {
  const name = {
    title: "Intitulé",
    dataIndex: "name",
    width: "22%",
  };
  const difficulty = {
    title: `${vocabulary.difficulty_label}`,
    dataIndex: "difficulty",
    width: "22%",
  };
  const majoration = {
    title: "Majoration",
    dataIndex: "majoration",
    width: "22%",
    hidden: !configuration.general.majoration,
  };
  const carrycot = {
    title: "Nacelles",
    dataIndex: "carrycot",
    width: "22%",
    hidden: !configuration.admin.carrycot_tab.visible,
  };
  const option = {
    title: "Opt.",
    dataIndex: "option",
    width: "7%",
    align: "center",
  };
  const deleteCol = {
    title: "",
    dataIndex: "delete",
    width: "5%",
    align: "center",
  };

  return [name, difficulty, majoration, carrycot, option, deleteCol].filter(
    (col) => !col.hidden
  );
};

const selector = (state) => ({
  formToken: state.formToken,
});

function Configurator({
  defaultValues,
  difficulties,
  majorations,
  carrycots,
  id_opportunity,
  zones,
  opportunity_work,
  configuration,
  vocabulary,
}) {
  const queryClient = useQueryClient();

  const { formToken } = useStore(selector);
  const [selectedZone, setSelectedZone] = useState();
  const [inputValue, setInputValue] = useState("");
  const [currentZoneTemplateSelected, setCurrentZoneTemplateSelected] =
    useState([]);

  useEffect(() => {
    if (selectedZone) {
      setSelectedZone(zones.find((el) => el.id === selectedZone.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zones]);

  const { data: zone_templates, refetch: zone_temmplate_refetch } = useQuery(
    "ZoneTemplates",
    () =>
      getData(
        formToken,
        `/zone/${id_opportunity}?from=opportunity_list_zone_template`
      )
  );

  const { mutate: createZone } = useCreateTodo(
    formToken,
    "/zone/create",
    "Zones"
  );

  const { mutate: deleteZone } = useDeleteTodo(
    formToken,
    "/zone/delete",
    "Zones"
  );

  const { mutate: updateZone } = useUpdateTodo(
    formToken,
    "/zone/update",
    "Zones",
    ["Zone"]
  );

  const {
    mutate: createZoneTemplate,
    isLoading: create_zone_template_loading,
  } = useMutation(
    (toDo) =>
      postData(formToken, "/zone/create_zone_template_in_opportunity", toDo),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("Zones");
      },
    }
  );

  const { mutate: updateZoneSpecificValue } = useMutation(
    (toDo) => postData(formToken, "/zone/update_specific_value", toDo),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("Zones");
        queryClient.invalidateQueries("Zone");
        queryClient.invalidateQueries("AssociatedItems");
        queryClient.refetchQueries([
          "Ouvrage",
          {
            zone_id: selectedZone.id,
          },
        ]);
      },
    }
  );

  const handleDifficulty = (difficulty) => {
    setSelectedZone({ ...selectedZone, difficulty_opportunity_id: difficulty });
    updateZone({ id: selectedZone.id, difficulty_opportunity_id: difficulty });
  };

  const handleUpdateDifficulty = (difficulty) => {
    setSelectedZone({ ...selectedZone, difficulty_opportunity_id: difficulty });
    updateZoneSpecificValue({
      id: selectedZone.id,
      difficulty_opportunity_id: difficulty,
    });
  };

  const handleUpdateMajoration = (majoration) => {
    setSelectedZone({ ...selectedZone, majoration_opportunity_id: majoration });
    updateZoneSpecificValue({
      id: selectedZone.id,
      majoration_opportunity_id: majoration,
    });
  };

  const handleChangeCurrentZoneTemplateSelected = (value) => {
    setCurrentZoneTemplateSelected(value);
  };

  const handleCreateZoneTemplate = (zone) => {
    createZoneTemplate({
      zone_ids: currentZoneTemplateSelected,
      opportunity_id: id_opportunity,
    });
  };

  const handleUpdateCarrycot = (carrycot) => {
    setSelectedZone({ ...selectedZone, carrycot_tpl_opportunity_id: carrycot });
    updateZoneSpecificValue({
      id: selectedZone.id,
      carrycot_tpl_opportunity_id: carrycot,
    });
  };

  const handleMajoration = (majoration) => {
    setSelectedZone({ ...selectedZone, majoration_opportunity_id: majoration });
    updateZone({ id: selectedZone.id, majoration_opportunity_id: majoration });
  };

  const handleCarrycot = (carrycot) => {
    setSelectedZone({ ...selectedZone, carrycot_tpl_opportunity_id: carrycot });
    if (!carrycot)
      updateZone({
        id: selectedZone.id,
        to_delete: "carrycot_tpl_opportunity_id",
      });
    else
      updateZone({
        id: selectedZone.id,
        carrycot_tpl_opportunity_id: carrycot,
      });
  };

  const handleAddZone = () => {
    if (inputValue !== "")
      createZone(
        JSON.stringify({
          zonable_id: id_opportunity,
          from: "opportunity",
          difficulty_opportunity_id:
            defaultValues?.[0]?.difficulty_opportunity_id,
          majoration_opportunity_id:
            defaultValues?.[0]?.majoration_opportunity_id,
          entitled: inputValue,
        })
      );
    setInputValue("");
  };

  const handleDeleteZone = (valueZone) => {
    deleteZone(JSON.stringify({ id: valueZone }));
  };

  const dataFactory = zones?.map((zone) => ({
    key: zone.id,
    name: zone.entitled,
    difficulty: zone.difficulty_opportunity?.name,
    majoration: zone.majoration_opportunity?.name,
    carrycot: zone.carrycot_tpl_opportunity?.name,
    option: (
      <Checkbox
        checked={zone.option}
        disabled={!configuration.option}
        onChange={(e) => {
          const isChecked = e.target.checked;
          updateZone({
            id: zone.id,
            option: isChecked,
          });
        }}
        onClick={(e) => e.stopPropagation()}
      />
    ),
    delete:
      zones.length > 1 &&
      zone.material_opportunities?.length < 1 &&
      zone.prestation_opportunities?.length < 1 ? (
        <Delete onClick={() => handleDeleteZone(zone.id)} />
      ) : null,
  }));

  return (
    <>
      <h3>Configurateur</h3>
      <Container>
        <Wrapper>
          <HeaderContainer>
            <h4>Zones</h4>
            <ContainerInput>
              <InputContainer>
                <Input
                  value={inputValue}
                  onInput={(e) => setInputValue(e.currentTarget.value)}
                  placeholder="Ajouter une nouvelle zone"
                />
                <Button
                  icon={<PlusOutlined />}
                  onClick={() => handleAddZone()}
                />
              </InputContainer>
              <InputContainer>
                <Select
                  mode="multiple"
                  keyName="entitled"
                  placeholder="Ajouter des zones templates"
                  options={zone_templates}
                  onChange={handleChangeCurrentZoneTemplateSelected}
                />
                <Button
                  icon={
                    !create_zone_template_loading ? (
                      <PlusOutlined />
                    ) : (
                      <Loader />
                    )
                  }
                  disabled={create_zone_template_loading}
                  onClick={() => handleCreateZoneTemplate()}
                />
              </InputContainer>
            </ContainerInput>
          </HeaderContainer>
          <TablePortion>
            <TableContainer
              bordered={false}
              scroll={{ y: "25vh" }}
              columns={getColumnsZones(
                opportunity_work,
                configuration,
                vocabulary
              )}
              dataSource={dataFactory}
              pagination={false}
              rowClassName={["cursor-pointer"]}
              onRow={(_, idx) => {
                return {
                  onClick: () => {
                    setSelectedZone(zones[idx]);
                  },
                };
              }}
            />
          </TablePortion>
        </Wrapper>
        <StyledSeparator size={3} />
        <Wrapper>
          {selectedZone && (
            <>
              <div className="default_value-title">
                <h4>Valeurs par défaut</h4>
              </div>
              <DefaultValuesContainer>
                <DefaultValueRow>
                  <StyledLabel>Intitulé</StyledLabel>
                  <StyledInput
                    placeholder="Intitulé"
                    status={selectedZone.entitled ? "" : "error"}
                    value={selectedZone.entitled}
                    onChange={(e) =>
                      setSelectedZone({
                        ...selectedZone,
                        entitled: e.currentTarget.value,
                      })
                    }
                    onBlur={(e) => {
                      if (e.currentTarget.value)
                        updateZone({
                          id: selectedZone.id,
                          entitled: e.currentTarget.value,
                        });
                    }}
                  />
                </DefaultValueRow>
                <DefaultValueRow>
                  <StyledLabel>{vocabulary.difficulty_label}</StyledLabel>
                  <StyledSelect
                    size="middle"
                    placeholder={vocabulary.difficulty_label}
                    value={selectedZone.difficulty_opportunity_id}
                    onChange={(e) => handleDifficulty(e)}
                    options={difficulties}
                  />
                  <Tooltip
                    title="Changer toutes les valeurs de la zone"
                    mouseLeaveDelay="0"
                  >
                    <StyledSwapButton
                      fontSize="14px"
                      type="button"
                      marginIcon="false"
                      onClick={() =>
                        handleUpdateDifficulty(
                          selectedZone.difficulty_opportunity_id
                        )
                      }
                    />
                  </Tooltip>
                </DefaultValueRow>
                {configuration.general.majoration && (
                  <DefaultValueRow>
                    <StyledLabel>Majoration</StyledLabel>
                    <StyledSelect
                      placeholder="Majoration"
                      onChange={(e) => handleMajoration(e)}
                      value={selectedZone.majoration_opportunity_id}
                      options={majorations}
                    />
                    <Tooltip
                      title="Changer toutes les valeurs de la zone"
                      mouseLeaveDelay="0"
                    >
                      <StyledSwapButton
                        fontSize="14px"
                        type="button"
                        marginIcon="false"
                        onClick={() =>
                          handleUpdateMajoration(
                            selectedZone.majoration_opportunity_id
                          )
                        }
                      />
                    </Tooltip>
                  </DefaultValueRow>
                )}
                {configuration.admin.carrycot_tab.visible && (
                  <DefaultValueRow>
                    <StyledLabel>Nacelles</StyledLabel>
                    <StyledSelect
                      allowClear
                      placeholder="Aucune"
                      value={selectedZone.carrycot_tpl_opportunity_id}
                      onChange={(e) => handleCarrycot(e)}
                      options={carrycots}
                    />
                    <Tooltip
                      title="Changer toutes les valeurs de la zone"
                      mouseLeaveDelay="0"
                    >
                      <StyledSwapButton
                        fontSize="14px"
                        type="button"
                        marginIcon="false"
                        onClick={() =>
                          handleUpdateCarrycot(
                            selectedZone.carrycot_tpl_opportunity_id
                          )
                        }
                      />
                    </Tooltip>
                  </DefaultValueRow>
                )}
                <DefaultValueRow>
                  <StyledLabel>Position</StyledLabel>
                  <StyledInput
                    placeholder="100"
                    status={selectedZone.position ? "" : "error"}
                    value={selectedZone.position}
                    onChange={(e) =>
                      setSelectedZone({
                        ...selectedZone,
                        position: e.currentTarget.value,
                      })
                    }
                    onBlur={(e) => {
                      if (e.currentTarget.value)
                        updateZone({
                          id: selectedZone.id,
                          position: e.currentTarget.value,
                        });
                    }}
                  />
                </DefaultValueRow>
                <DefaultValueRow>
                  <StyledLabel>Option</StyledLabel>
                  <Checkbox
                    checked={selectedZone.option}
                    disabled={!configuration.option}
                    onChange={(e) => {
                      const isChecked = e.target.checked; // récupérer la valeur coché
                      setSelectedZone({
                        ...selectedZone,
                        option: isChecked, // mettre à jour l'état local avec la nouvelle valeur
                      });
                      updateZone({
                        id: selectedZone.id,
                        option: isChecked, // utiliser la valeur mise à jour
                      });
                    }}
                  />
                </DefaultValueRow>
              </DefaultValuesContainer>
            </>
          )}
          {!selectedZone && (
            <ZoneUnselectedContainer>
              Sélectionnez une zone
            </ZoneUnselectedContainer>
          )}
        </Wrapper>
      </Container>
    </>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;
  margin-top: 30px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const TablePortion = styled.div`
  height: 30vh;
`;

const ZoneUnselectedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const StyledSeparator = styled(Separator)`
  margin: 0 20px;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
`;

const InputContainer = styled.div`
  display: flex;
  width: 90%;
`;

const DefaultValuesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const DefaultValueRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;

const StyledLabel = styled.label`
  width: 25%;
`;

const StyledInput = styled(Input)`
  width: 50%;
`;

const StyledSelect = styled(Select)`
  width: 50%;
`;
const buttonStyle = css`
  width: 25px;
  margin-left: 10px;
`;

const StyledSwapButton = styled(SwapButton)`
  ${buttonStyle}
`;

const ContainerInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: 20px;
`;

export default Configurator;
