import React, { useState, useMemo } from "react";
import styled from "styled-components";
import { useMutation } from "react-query";
import { Modal } from "antd";
import _ from "lodash";
import Button from "../react-ui/Button";
import { postData } from "../request/instance";
import JobsAvailable from "./works_available";
import Form from "../Form";
import AddButton from "../react-ui/AddButton";
import WorkEdit from "./work_edit";
import { formatNumberString } from "../../utils/formatNumberString";

function WorkAndSub({
  token,
  company,
  available_works,
  user_works,
  works,
  list_api_providers,
  user_roles,
  procurement,
  all_works_configuration,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [worksData, setWorksData] = useState(works);
  const [formEditMode, setFormEditMode] = useState(false);
  const [availableWorks, setAvailableWorks] = useState(available_works);
  const { mutate: createCompanyWork } = useMutation(
    (todo) =>
      postData(token, `/admin/companies/${company.id}/company_works`, todo),
    {
      onSuccess: (payload) => {
        setAvailableWorks((prev) =>
          prev.filter((work) => work.id !== workSelected)
        );
        setWorksData((prev) => {
          const payloadData = payload;
          return [...prev, payloadData];
        });
        setWorkSelected(null);
      },
    }
  );
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const check_if_provider = () => {
    const external_api = all_works_configuration?.find(
      (wv) => wv?.work_id === workSelected
    )?.external_api;

    if (
      external_api === null ||
      external_api === undefined ||
      workSelected === null
    ) {
      return false; // Return false if external_api is null or undefined
    }

    const atLeastOneTrue = Object.values(external_api)?.some(
      (value) => value === true
    );
    return atLeastOneTrue;
  };

  const handleSelectWork = (work_id) => {
    setWorkSelected(work_id);
  };

  const [workSelected, setWorkSelected] = useState();
  const WorkFormFactory = (initialWork) => {
    return useMemo(() => {
      const firstRow = [
        {
          type: "select",
          item: {
            select: {
              onChange: (e) => {
                handleSelectWork(e);
              },
              placeholder: "Sélectionnez un métier",
              disabled: false,
            },
            options: availableWorks,
            keyName: "name",
            name: "work_id",
            label: "Métiers",
            rules: [
              {
                required: true,
                message: "Champ obligatoire.",
              },
            ],
          },
        },
      ];

      const secondRow = [
        {
          type: "input",
          item: {
            input: {
              disabled: false,
              onInput: (e) => {
                e.currentTarget.value = formatNumberString({
                  str: e.currentTarget.value,
                  nbDecimal: 0,
                });
              },
            },
            name: "admin_licences",
            isComputed: true,
            isMarginRate: true,
            label: "Administrateur",
            keyName: "admin",
            rules: [
              {
                required: true,
                message: "Champ obligatoire.",
              },
            ],
          },
        },
        {
          type: "input",
          item: {
            input: {
              disabled: false,
              onInput: (e) => {
                e.currentTarget.value = formatNumberString({
                  str: e.currentTarget.value,
                  nbDecimal: 0,
                });
              },
            },
            name: "manager_licences",
            isComputed: true,
            isMarginRate: true,
            keyName: "manager",
            label: "Manager",
            rules: [
              {
                required: true,
                message: "Champ obligatoire.",
              },
            ],
          },
        },
      ];
      const thirdRow = [
        {
          type: "input",
          item: {
            input: {
              disabled: false,
              onInput: (e) => {
                e.currentTarget.value = formatNumberString({
                  str: e.currentTarget.value,
                  nbDecimal: 0,
                });
              },
            },
            isComputed: true,
            isMarginRate: true,
            name: "setup_licences",
            label: "Paramétreur",
            rules: [
              {
                required: true,
                message: "Champ obligatoire.",
              },
            ],
          },
        },
        {
          type: "input",
          item: {
            input: {
              disabled: false,
              onInput: (e) => {
                e.currentTarget.value = formatNumberString({
                  str: e.currentTarget.value,
                  nbDecimal: 0,
                });
              },
            },
            isComputed: true,
            isMarginRate: true,
            name: "salesman_licences",
            label: "Commercial",
            rules: [
              {
                required: true,
                message: "Champ obligatoire.",
              },
            ],
          },
        },
      ];

      const fourthRow = [
        ...(!procurement
          ? [
              {
                type: "input",
                item: {
                  input: {
                    disabled: false,
                    onInput: (e) => {
                      e.currentTarget.value = formatNumberString({
                        str: e.currentTarget.value,
                        nbDecimal: 0,
                      });
                    },
                  },
                  isComputed: true,
                  isMarginRate: true,
                  name: "supervisor_licences",
                  label: "superviseur",
                  rules: [
                    {
                      required: true,
                      message: "Champ obligatoire.",
                    },
                  ],
                },
              },
            ]
          : []),
      ];

      const ProviderRow = [
        {
          type: "select",
          item: {
            select: {
              placeholder: "Choisissez des api",
              disabled: false,
              mode: "multiple",
            },
            options: list_api_providers.filter((api) => api.name !== "smart4"),
            keyName: "name",
            name: "api_providers",
            label: "Fournisseur api",
          },
        },
      ];

      const ProcurementRow = [
        {
          type: "input",
          item: {
            input: {
              disabled: false,
            },
            name: "site_licences",
            label: "Sites",
            rules: [
              {
                required: true,
                message: "Champ obligatoire.",
              },
            ],
          },
        },
      ];

      return [
        { columns: firstRow, key: "work_id" },
        ...(procurement
          ? [{ columns: ProcurementRow, key: "site_licences" }]
          : []),
        ...(!procurement
          ? [
              { columns: secondRow, key: "admin_licences+manager_licences" },
              { columns: thirdRow, key: "setup_licences+salesman_licences" },
              { columns: fourthRow, key: "supervisor_licences" },
            ]
          : []),
        ...(check_if_provider()
          ? [{ columns: ProviderRow, key: "api_providers" }]
          : []),
      ];
    }, [initialWork, workSelected]);
  };

  const handleRegisterOuvrageProfession = (e) => {
    const formData = new FormData();

    formData.append("company_work[company_id]", company.id);
    formData.append("company_work[work_id]", e.work_id);
    formData.append("company_work[admin_licences]", e.admin_licences);
    formData.append("company_work[supervisor_licences]", e.supervisor_licences);
    formData.append("company_work[manager_licences]", e.manager_licences);
    formData.append("company_work[salesman_licences]", e.salesman_licences);
    formData.append("company_work[setup_licences]", e.setup_licences);
    if (e.api_providers?.length > 0) {
      const api_providers = `${e.api_providers}, ${
        list_api_providers.find((api) => api.name === "smart4").id
      }`;
      formData.append("company_work[api_provider_ids]", api_providers);
    } else {
      formData.append(
        "company_work[api_provider_ids]",
        list_api_providers.find((api) => api.name === "smart4").id
      );
    }
    formData.append("company_work[site_licences]", e.site_licences);
    createCompanyWork(formData);
  };

  const onSubmit = (e) => {
    handleRegisterOuvrageProfession(e);
  };
  return (
    <div>
      {_.includes(user_roles, "superadmin") && (
        <div style={{ padding: 7 }}>
          <AddButton
            label="Ajouter un métier"
            fontSize={14}
            onClick={showModal}
          />
        </div>
      )}
      <Container>
        <RowArray>
          <JobsAvailable
            works={worksData}
            user_works={user_works}
            all_works_configuration={all_works_configuration}
            formEditMode={formEditMode}
          />
        </RowArray>
        <RowEdit>
          <WorkEdit
            worksData={worksData}
            setWorksData={setWorksData}
            formEditMode={formEditMode}
            setFormEditMode={setFormEditMode}
            token={token}
            list_api_providers={list_api_providers}
            company={company}
            procurement={procurement}
            user_roles={user_roles}
          />
        </RowEdit>
      </Container>
      <Modal
        width={800}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
            <Button key="cancel" onClick={handleCancel}>
              Annuler
            </Button>
            <AddButton
              onClick={handleOk}
              label="Enregistrer"
              type="submit"
              value="submit"
              fontSize="14px"
              form="company-work-form-create"
            />
          </div>,
        ]}
      >
        <h2>Ajouter un métier</h2>
        <Form
          rows={WorkFormFactory()}
          onSubmit={onSubmit}
          initialValues={{
            work_id: workSelected || null,
          }}
          id="company-work-form-create"
        />
      </Modal>
    </div>
  );
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const RowArray = styled.div`
  width: 30%;
  margin: 2px;
`;

const RowEdit = styled.div`
  width: 70%;
  margin: 2px;
`;

export default WorkAndSub;
