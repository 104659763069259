export function updateStateGeneral(
  file,
  setClients,
  setGeneral,
  setProducts,
  setPrestations,
  setProfils,
  setTextBlock,
  setCategories,
  props,
  checkIsItemInOpportunityVariable,
  general
) {
  setGeneral({
    pageSelectionItems: file?.costing.page_1?.visible,
    accordCadre: file?.general?.framework_agreement,
    maintenance: file?.general?.maintenance,
    deplacements: file?.admin?.movement_tab?.visible,
    makers: file?.admin?.maker_tab?.visible,
    carrycotsMovement: file?.costing?.page_2?.visible,
    offreLocative: file?.general?.renting,
    regroupement: file?.admin?.grouping_tab?.visible,
    carrycots: file?.admin?.carrycot_tab?.visible,
    distributors: file?.admin?.distributor_tab?.visible,
    prixVente: file?.costing?.page_3?.visible,
    ouvrage: file?.ouvrage,
    batiprix: file?.external_api?.batiprix,
    profils: file?.admin?.profil_tab?.visible,
    groupement: file?.group,
    option: file?.option,
  });

  setProducts({
    marge: file?.admin?.product_tab?.form?.input_margin_rate,
    colonneMarge: file?.admin?.product_tab?.table_marge_coeff,
    dateValidite: file?.admin?.product_tab?.form?.input_date_validity,
    associerProduits:
      file?.admin?.product_tab?.form?.product_associate?.input_linked,
  });

  setPrestations({
    margePrestation: file?.admin?.prestation_tab?.form?.input_margin_rate,
    description: file?.admin?.prestation_tab?.form?.input_description,
    speed: file?.admin?.prestation_tab?.form?.input_speed,
    majoration: file?.admin?.prestation_tab?.form?.input_majoration,
  });

  setProfils({
    margeProfil: file?.admin?.profil_tab?.form?.input_margin_rate,
  });

  setCategories({
    margeCategorie: file?.admin?.categories_tab?.form?.input_margin_rate,
  });

  setClients({
    activiteRequise: file?.clients?.required_activity,
  });

  setTextBlock({
    opportunityName: file?.api?.opportunity_variable?.NAME,
    offerName: file?.api?.opportunity_variable?.OFFER_NAME,
    opportunityValidityDate: file?.api?.opportunity_variable?.VALIDITY_DATE,
    opportunityCreatedDate:
      file?.api?.opportunity_variable?.OPPORTUNITY_CREATED_DATE,
    chono: file?.api?.opportunity_variable?.CHRONO,
    opportunityStartDate: checkIsItemInOpportunityVariable(
      "opportunity_date",
      "mandatory",
      file
    )
      ? file?.api?.opportunity_variable?.QUOTATION_START_DATE
      : false,
    opportunityEndDate: checkIsItemInOpportunityVariable(
      "opportunity_date",
      "mandatory",
      file
    )
      ? file?.api?.opportunity_variable?.QUOTATION_END_DATE
      : false,
    opportunitySubmissionDate: checkIsItemInOpportunityVariable(
      "opportunity_limit_date",
      "mandatory",
      file
    )
      ? file?.api?.opportunity_variable?.SUBMISSION_DATE
      : false,

    opportunityType: file?.api?.opportunity_variable?.OPPORTUNITY_TYPE,
    responseType: checkIsItemInOpportunityVariable(
      "opportunity_response_type",
      "mandatory",
      file
    )
      ? file?.api?.opportunity_variable?.RESPONSE_TYPE
      : false,
    customerDecisionDate: checkIsItemInOpportunityVariable(
      "decision_date",
      "complement",
      file
    )
      ? file?.api?.opportunity_variable?.CUSTOMER_DECISION_DATE
      : false,
    description: checkIsItemInOpportunityVariable(
      "opportunity_description",
      "complement",
      file
    )
      ? file?.api?.opportunity_variable?.DESCRIPTION
      : false,
    estimatedAmount: checkIsItemInOpportunityVariable(
      "estimate_price",
      "complement",
      file
    )
      ? file?.api?.opportunity_variable?.ESTIMATED_AMOUNT
      : false,
    siteTypologies: checkIsItemInOpportunityVariable(
      "type_of_site",
      "complement",
      file
    )
      ? file?.api?.opportunity_variable?.SITE_TYPOLOGIES
      : false,
    sellingPrice: general.prixVente
      ? file?.api?.opportunity_variable?.SELLING_PRICE
      : false,
    sellingPriceTTC: file?.api?.opportunity_variable?.SELLING_PRICE_VAT,
    successProbability: checkIsItemInOpportunityVariable(
      "probability_success",
      "complement",
      file
    )
      ? file?.api?.opportunity_variable?.SUCCESS_PROBABILITY
      : false,
    cancellationProbability: checkIsItemInOpportunityVariable(
      "probability_cancelation",
      "complement",
      file
    )
      ? file?.api?.opportunity_variable?.CANCELLATION_PROBABILITY
      : false,
    providers: checkIsItemInOpportunityVariable(
      "provider_in_place",
      "complement",
      file
    )
      ? file?.api?.opportunity_variable?.PROVIDERS
      : false,
    frameworkAgreement: file?.api?.opportunity_variable?.FRAMEWORK_AGREEMENT,
  });
}

export function updateArrayBlockStates(
  configuration,
  setDevisItems,
  setOptionItems,
  setProductItems,
  setPrestationItems,
  setOuvrageArticlesItems,
  setContactItems,
  setReferenceItems,
  setHeadOfficeItems,
  setEntityItems,
  general,
  checkIsItemInApiOpportunity,
  checkIsItemInOpportunityVariable
) {
  setDevisItems([
    {
      initialValue: general.prixVente
        ? checkIsItemInApiOpportunity("pricesWork")
        : false,
      isDisabled: !general.prixVente,
      labelText: "Devis détaillé sans sous-totaux",
      name: "pricesWork",
    },
    {
      initialValue: general.prixVente
        ? checkIsItemInApiOpportunity("detailedPricesWork")
        : false,
      isDisabled: !general.prixVente,
      labelText: "Devis détaillé avec sous-totaux",
      name: "detailedPricesWork",
    },
    {
      initialValue: general.prixVente
        ? checkIsItemInApiOpportunity("detailedPricesSubtotalPricesOnlyWork")
        : false,
      isDisabled: !general.prixVente,
      labelText: "Devis non détaillés avec sous-totaux",
      name: "detailedPricesSubtotalPricesOnlyWork",
    },
    {
      initialValue: general.prixVente
        ? checkIsItemInApiOpportunity("detailedPricesOnlyTotalPriceWork")
        : false,
      isDisabled: !general.prixVente,
      labelText: "Devis non détaillé",
      name: "detailedPricesOnlyTotalPriceWork",
    },
  ]);
  setOptionItems([
    {
      initialValue:
        general.prixVente && general.option
          ? checkIsItemInApiOpportunity("pricesOptionWork")
          : false,
      isDisabled: !general.prixVente || !general.option,
      labelText: "Option - Devis détaillé sans sous-totaux",
      name: "pricesOptionWork",
    },
    {
      initialValue:
        general.prixVente && general.option
          ? checkIsItemInApiOpportunity("detailedPricesOptionWork")
          : false,
      isDisabled: !general.prixVente || !general.option,
      labelText: "Option - Devis détaillé avec sous-totaux",
      name: "detailedPricesOptionWork",
    },
    {
      initialValue:
        general.prixVente && general.option
          ? checkIsItemInApiOpportunity(
              "detailedPricesOptionSubtotalPricesOnlyWork"
            )
          : false,
      isDisabled: !general.prixVente || !general.option,
      labelText: "Option - Devis non détaillé avec sous-totaux",
      name: "detailedPricesOptionSubtotalPricesOnlyWork",
    },
    {
      initialValue:
        general.prixVente && general.option
          ? checkIsItemInApiOpportunity(
              "detailedPricesOptionOnlyTotalPriceWork"
            )
          : false,
      isDisabled: !general.prixVente || !general.option,
      labelText: "Option - Devis non détaillé",
      name: "detailedPricesOptionOnlyTotalPriceWork",
    },
    {
      initialValue:
        general.pageSelectionItems && general.option
          ? checkIsItemInApiOpportunity("materialsOptionWork")
          : false,
      isDisabled: !general.pageSelectionItems || !general.option,
      labelText: "Option - Produits liste",
      name: "materialsOptionWork",
    },
    {
      initialValue:
        general.pageSelectionItems && general.option
          ? checkIsItemInApiOpportunity("materialsOptionZonesWork")
          : false,
      isDisabled: !general.pageSelectionItems || !general.option,
      labelText: "Option - Produits liste par zone",
      name: "materialsOptionZonesWork",
    },
    {
      initialValue:
        general.pageSelectionItems && general.option
          ? checkIsItemInApiOpportunity("fonctionnalitiesOptionWork")
          : false,
      isDisabled: !general.pageSelectionItems || !general.option,
      labelText: "Produits fonctionnalités",
      name: "fonctionnalitiesOptionWork",
    },
    {
      initialValue:
        general.pageSelectionItems && general.option
          ? checkIsItemInApiOpportunity("fonctionnalitiesOptionZonesWork")
          : false,
      isDisabled: !general.pageSelectionItems || !general.option,
      labelText: "Produits fonctionnalités par zone",
      name: "fonctionnalitiesOptionZonesWork",
    },
    {
      initialValue:
        general.pageSelectionItems && general.option
          ? checkIsItemInApiOpportunity("prestationsOptionWork")
          : false,
      isDisabled: !general.pageSelectionItems || !general.option,
      labelText: "Option - Prestations Liste",
      name: "prestationsOptionWork",
    },
    {
      initialValue:
        general.pageSelectionItems && general.option
          ? checkIsItemInApiOpportunity("prestationsOptionZonesWork")
          : false,
      isDisabled: !general.pageSelectionItems || !general.option,
      labelText: "Option - Prestations Liste par zone",
      name: "prestationsOptionZonesWork",
    },
    {
      initialValue:
        general.pageSelectionItems && general.option
          ? checkIsItemInApiOpportunity("prestationOptionDescriptionWork")
          : false,
      isDisabled: !general.pageSelectionItems || !general.option,
      labelText: "Option - Prestations (Intitulé | Description)",
      name: "prestationOptionDescriptionWork",
    },
    {
      initialValue:
        general.pageSelectionItems && general.option
          ? checkIsItemInApiOpportunity("prestationOptionPictureWork")
          : false,
      isDisabled: !general.pageSelectionItems || !general.option,
      labelText: "Option - Prestations (Photo | Intitulé | Description)",
      name: "prestationOptionPictureWork",
    },
    {
      initialValue:
        general.pageSelectionItems && general.option
          ? checkIsItemInApiOpportunity("ouvragesOptionWork")
          : false,
      isDisabled: !general.pageSelectionItems || !general.option,
      labelText: "Option - Ouvrages",
      name: "ouvragesOptionWork",
    },
    {
      initialValue:
        general.pageSelectionItems && general.option
          ? checkIsItemInApiOpportunity("ouvragesOptionDetailsWork")
          : false,
      isDisabled: !general.pageSelectionItems || !general.option,
      labelText: "Option - Ouvrages Détails",
      name: "ouvragesOptionDetailsWork",
    },
    {
      initialValue:
        checkIsItemInApiOpportunity("allArticlesOptionWithoutPricesWork") &&
        general.option,
      isDisabled: !general.option,
      labelText: "Option - Tous les articles",
      name: "allArticlesOptionWithoutPricesWork",
    },
    {
      initialValue:
        checkIsItemInApiOpportunity(
          "allArticlesOptionWithoutPricesZonesWork"
        ) && general.option,
      isDisabled: !general.option,
      labelText: "Option - Tous les articles par zone",
      name: "allArticlesOptionWithoutPricesZonesWork",
    },
  ]);
  setProductItems([
    {
      initialValue: general.pageSelectionItems
        ? checkIsItemInApiOpportunity("materialsWork")
        : false,
      isDisabled: !general.pageSelectionItems,
      labelText: "Produits liste",
      name: "materialsWork",
    },
    {
      initialValue: general.pageSelectionItems
        ? checkIsItemInApiOpportunity("materialsZonesWork")
        : false,
      isDisabled: !general.pageSelectionItems,
      labelText: "Produits liste par zone",
      name: "materialsZonesWork",
    },
    {
      initialValue: general.pageSelectionItems
        ? checkIsItemInApiOpportunity("fonctionnalitiesWork")
        : false,
      isDisabled: !general.pageSelectionItems,
      labelText: "Produits fonctionnalités",
      name: "fonctionnalitiesWork",
    },
    {
      initialValue: general.pageSelectionItems
        ? checkIsItemInApiOpportunity("fonctionnalitiesZonesWork")
        : false,
      isDisabled: !general.pageSelectionItems,
      labelText: "Produits fonctionnalités par zone",
      name: "fonctionnalitiesZonesWork",
    },
  ]);

  setPrestationItems([
    {
      initialValue: general.pageSelectionItems
        ? checkIsItemInApiOpportunity("prestationsWork")
        : false,
      isDisabled: !general.pageSelectionItems,
      labelText: "Liste",
      name: "prestationsWork",
    },
    {
      initialValue: general.pageSelectionItems
        ? checkIsItemInApiOpportunity("prestationsZonesWork")
        : false,
      isDisabled: !general.pageSelectionItems,
      labelText: "Liste par zone",
      name: "prestationsZonesWork",
    },
    {
      initialValue: general.pageSelectionItems
        ? checkIsItemInApiOpportunity("prestationDescriptionWork")
        : false,
      isDisabled: !general.pageSelectionItems,
      labelText: "Intitulé | Description",
      name: "prestationDescriptionWork",
    },
    {
      initialValue: general.pageSelectionItems
        ? checkIsItemInApiOpportunity("prestationPictureWork")
        : false,
      isDisabled: !general.pageSelectionItems,
      labelText: "Photo | Intitulé | Description",
      name: "prestationPictureWork",
    },
  ]);
  setOuvrageArticlesItems([
    {
      initialValue: general.pageSelectionItems
        ? checkIsItemInApiOpportunity("ouvragesWork")
        : false,
      isDisabled: !general.pageSelectionItems,
      labelText: "Ouvrages",
      name: "ouvragesWork",
    },
    {
      initialValue: general.pageSelectionItems
        ? checkIsItemInApiOpportunity("ouvragesDetailsWork")
        : false,
      isDisabled: !general.pageSelectionItems,
      labelText: "Ouvrages Détails",
      name: "ouvragesDetailsWork",
    },
    {
      initialValue: checkIsItemInApiOpportunity("allArticlesWithoutPricesWork"),
      labelText: "Tous les articles",
      name: "allArticlesWithoutPricesWork",
    },
    {
      initialValue: checkIsItemInApiOpportunity(
        "allArticlesWithoutPricesZonesWork"
      ),
      labelText: "Tous les articles par zone",
      name: "allArticlesWithoutPricesZonesWork",
    },
  ]);

  setContactItems([
    {
      initialValue: checkIsItemInOpportunityVariable(
        "client_contacts",
        "mandatory",
        configuration
      )
        ? checkIsItemInApiOpportunity("clientContactsWork")
        : false,
      isDisabled: !checkIsItemInOpportunityVariable(
        "client_contacts",
        "mandatory",
        configuration
      ),
      labelText: "Client facturé",
      name: "clientContactsWork",
    },
    {
      initialValue: checkIsItemInOpportunityVariable(
        "provided_customer_contacts",
        "complement",
        configuration
      )
        ? checkIsItemInApiOpportunity("providedCustomerContactsWork")
        : false,
      isDisabled: !checkIsItemInOpportunityVariable(
        "provided_customer_contacts",
        "complement",
        configuration
      ),
      labelText: "Client presté",
      name: "providedCustomerContactsWork",
    },
    {
      initialValue: checkIsItemInOpportunityVariable(
        "decision_maker_customer_contacts",
        "complement",
        configuration
      )
        ? checkIsItemInApiOpportunity("decisionMakerCustomerContactsWork")
        : false,
      isDisabled: !checkIsItemInOpportunityVariable(
        "decision_maker_customer_contacts",
        "complement",
        configuration
      ),
      labelText: "Client décisionnaire",
      name: "decisionMakerCustomerContactsWork",
    },
    {
      initialValue: checkIsItemInOpportunityVariable(
        "contacts_work",
        "complement",
        configuration
      )
        ? checkIsItemInApiOpportunity("contactsWork")
        : false,
      isDisabled: !checkIsItemInOpportunityVariable(
        "contacts_work",
        "complement",
        configuration
      ),
      labelText: "Général",
      name: "contactsWork",
    },
  ]);

  setReferenceItems([
    {
      initialValue: checkIsItemInApiOpportunity("referencesGlobal"),
      labelText: "Réferences générales",
      name: "referencesGlobal",
    },
    {
      initialValue: checkIsItemInApiOpportunity("referencesGlobalTypology"),
      labelText: "Références générales liées à la typologie",
      name: "referencesGlobalTypology",
    },
  ]);

  setHeadOfficeItems([
    {
      initialValue: checkIsItemInApiOpportunity("headOfficeTurnoverWork"),
      labelText: "Les 3 derniers CA",
      name: "headOfficeTurnoverWork",
    },
    {
      initialValue: checkIsItemInApiOpportunity(
        "headOfficeTurnoverRepartitionWork"
      ),
      labelText: "Les 3 derniers CA + répartition",
      name: "headOfficeTurnoverRepartitionWork",
    },
    {
      initialValue: checkIsItemInApiOpportunity("referencesGlobalHeadOffice"),
      labelText: "Références générales",
      name: "referencesGlobalHeadOffice",
    },
    {
      initialValue: checkIsItemInApiOpportunity("referencesTypologyHeadOffice"),
      labelText: "Références générales liées à la typologie",
      name: "referencesTypologyHeadOffice",
    },
  ]);

  setEntityItems([
    {
      initialValue: checkIsItemInApiOpportunity("entityTurnoverWork"),
      labelText: "Les 3 derniers CA",
      name: "entityTurnoverWork",
    },
    {
      initialValue: checkIsItemInApiOpportunity(
        "entityTurnoverRepartitionWork"
      ),
      labelText: "Les 3 derniers CA + répartition",
      name: "entityTurnoverRepartitionWork",
    },
    {
      initialValue: checkIsItemInApiOpportunity("referencesGlobalEntity"),
      labelText: "Références générales",
      name: "referencesGlobalEntity",
    },
    {
      initialValue: checkIsItemInApiOpportunity("referencesTypologyEntity"),
      labelText: "Références générales liées à la typologie",
      name: "referencesTypologyEntity",
    },
  ]);
}

export function checkIsItemInOpportunityVariable(item, type, file) {
  const typeOpportunity =
    type === "mandatory"
      ? file?.opportunity?.mandatory
      : file?.opportunity?.complement;

  if (!typeOpportunity) {
    return false;
  }

  // Parcourir chaque colonne (col_1, col_2, etc.)
  for (const col of Object.values(typeOpportunity)) {
    // Parcourir chaque ligne dans la colonne (line_1, line_2, etc.)
    for (const line of Object.values(col)) {
      // Vérifier si 'name' est un tableau et si l'item est inclus dedans
      if (Array.isArray(line.name) && line.name.includes(item)) {
        return true;
      }
    }
  }

  return false;
}
