import React from "react";
import { Route, Routes } from "react-router-dom";
import Costing3 from "../Chiffrage/CostingLowCurrent3/CostingLowCurrent3";
import CostingMaterialsPrestations from "../Chiffrage/CostingMaterialsPrestations";
import CostingMovementsCarrycots from "../Chiffrage/CostingMovementsCarrycots";
import CostingReferencing from "../Chiffrage/CostingReferencing";
import CostingRenting from "../Chiffrage/CostingRenting";
import Opportunity from "../Chiffrage/OpportunityDetails";
import WorkPositions from "../Guarding/WorkPositions";
import { useStore } from "../store";
import Breadcrumb from "./Breadcrumb";
import Agents from "../Guarding/Agents";

const selector = (state) => ({
  formToken: state.formToken,
});

function OpportunitiesRoutes(props) {
  const { formToken } = useStore(selector);
  return (
    <>
      <Breadcrumb
        isBuyer={props.is_buyer}
        opportunity={props.opportunity}
        offer_name={props.offer_name}
        opportunity_work={props.opportunity_work}
        configuration={props.configuration}
        vocabulary={props.vocabulary}
        vocabularyApplication={props.vocabulary_application}
        is_last_page={props.is_last_page}
        site_typologies={props.site_typologies}
        supervisor={props.supervisor}
        documents_disabled={props.documents_disabled}
      />
      <Routes>
        <Route
          path="new"
          element={
            <Opportunity
              works={props.works}
              entities={props.entities}
              userEntityId={props.user_entity_id}
              userId={props.user_id}
              offerId={props.offer_id}
              contacts={props.contacts}
              companyId={props.company_id}
              clientActivities={props.client_activities}
              offerDeliveryDate={props.offer_delivery_date}
              companyWorks={props.company_works}
              rentalOffers={props.rental_offers}
              opportunity_work={props.opportunity_work}
              configuration={props.configuration}
              vocabulary={props.vocabulary}
              configApplication={props.config_application}
              vocabularyApplication={props.vocabulary_application}
              userClientIds={props.user_client_ids}
              procurement={props.procurement}
              isLockedByUser
            />
          }
        />
        <Route
          path=":id_opportunity/edit"
          element={
            <Opportunity
              procurement={props.procurement}
              userClientIds={props.user_client_ids}
              works={props.works}
              entities={props.entities}
              userEntityId={props.user_entity_id}
              userId={props.user_id}
              offerId={props.offer_id}
              contacts={props.contacts}
              companyId={props.company_id}
              clientActivities={props.client_activities}
              opportunity={props.opportunity}
              companyWorks={props.company_works}
              configuration={props.configuration}
              vocabulary={props.vocabulary}
              configApplication={props.config_application}
              isLockedByUser={props.is_locked_by_user}
              rentalOffers={props.rental_offers}
            />
          }
        />
        <Route
          path=":id_opportunity/costing/1"
          element={
            <>
              {props.response_type !== "referencing" && (
                <CostingMaterialsPrestations
                  opportunityType={props.opportunity_type}
                  faId={props.framework_agreement_id}
                  opportunity_work={props.opportunity_work}
                  api_providers={props.api_providers}
                  configuration={props.configuration}
                  vocabulary={props.vocabulary}
                  minutes={props.minutes}
                  company_work={props.company_work}
                />
              )}
              {props.response_type === "referencing" && (
                <CostingReferencing
                  formToken={formToken}
                  entityWorkId={props.entity_work_id}
                  configuration={props.configuration}
                  vocabulary={props.vocabulary}
                />
              )}
            </>
          }
        />
        <Route
          path=":id_opportunity/costing/2"
          element={
            props.response_type !== "referencing" ? (
              <CostingMovementsCarrycots
                formToken={formToken}
                entity_work_id={props.entity_work_id}
                faId={props.framework_agreement_id}
                configuration={props.configuration}
                vocabulary={props.vocabulary}
              />
            ) : null
          }
        />
        <Route
          path=":id_opportunity/costing/3"
          element={
            <>
              {props.response_type === "renting" && (
                <CostingRenting
                  opportunityId={props.opportunity_id}
                  offerId={props.offer_id}
                  configuration={props.configuration}
                  vocabulary={props.vocabulary}
                />
              )}
              {props.response_type !== "renting" && (
                <Costing3
                  responseType={props.response_type}
                  opportunityType={props.opportunity_type}
                  opportunityId={props.opportunity_id}
                  chrono={props.chrono}
                  hasSummaryExcel={props.option_financial_summary_excel}
                  hasRenting={props.renting}
                  opportunity_work={props.opportunity_work}
                  configuration={props.configuration}
                  vocabulary={props.vocabulary}
                />
              )}
            </>
          }
        />
        <Route
          path=":id_opportunity/costing/4"
          element={
            <CostingRenting
              opportunityId={props.opportunity_id}
              offerId={props.offer_id}
              configuration={props.configuration}
              vocabulary={props.vocabulary}
            />
          }
        />
        <Route
          path=":id_opportunity/work_positions"
          element={
            <WorkPositions
              profils={props.profils}
              opportunityId={props.opportunity_id}
              plannings={props.plannings}
              nightTimeStart={props.night_time_start}
              nightTimeEnd={props.night_time_end}
              quotationStartDate={props.quotation_start_date}
              quotationEndDate={props.quotation_end_date}
              configApplication={props.config_application}
              chrono={props.chrono}
              isLockedByUser={props.is_locked_by_user}
            />
          }
        />
        <Route
          path=":id_opportunity/agents"
          element={
            <Agents
              opportunityId={props.opportunity_id}
              qualifications={props.qualifications}
              contractTypes={props.contract_types}
              frequencies={props.frequencies}
              configApplication={props.config_application}
              coeffToMonthly={props.coeff_to_monthly}
              isLockedByUser={props.is_locked_by_user}
            />
          }
        />
      </Routes>
    </>
  );
}

export default OpportunitiesRoutes;
