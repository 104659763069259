import React, { forwardRef, useMemo, useRef } from "react";
import { Input as AntdInput } from "antd";
import { bool, node, oneOfType, string } from "prop-types";
import styled, { css } from "styled-components";
import { useStore } from "../store";

const selector = (state) => ({
  isUsingMargin: state.isUsingMargin,
});
const Input = forwardRef(
  (
    {
      className,
      fontSize,
      textArea = false,
      textAlign,
      isMarginRate,
      placeholder,
      warningInput,
      customContent = null,
      ...inputProps
    },
    ref
  ) => {
    const { isUsingMargin } = useStore(selector);

    const constructedPlaceholder = useMemo(() => {
      if (placeholder) return placeholder;
      if (isMarginRate) {
        return isUsingMargin ? "0,00" : "0";
      }
      return undefined;
    }, [isMarginRate, isUsingMargin, placeholder]);

    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        ref.current.blur(); // Trigger the blur event
      }
    };

    return (
      <>
        {textArea ? (
          <StyledTextArea
            fontSize={fontSize}
            $textAlign={textAlign}
            $warningInput={warningInput}
            className={className}
            placeholder={constructedPlaceholder}
            onKeyDown={handleKeyDown}
            ref={ref}
            {...inputProps}
          />
        ) : (
          <StyledInput
            fontSize={fontSize}
            $textAlign={textAlign}
            $warningInput={warningInput}
            className={className}
            suffix={isMarginRate && isUsingMargin ? "%" : inputProps.suffix}
            placeholder={constructedPlaceholder}
            ref={ref}
            onKeyDown={handleKeyDown}
            {...inputProps}
          />
        )}
        {customContent && <StyledText>{customContent}</StyledText>}
      </>
    );
  }
);

const shared = css`
  ${({ $warningInput, status }) =>
    $warningInput &&
    !!status &&
    css`
      input {
        background-color: ${() =>
          status === "warning" ? "#faad14 !important" : "#ff4d4f !important"};
      }
      background-color: ${() =>
        status === "warning" ? "#faad14 !important" : "#ff4d4f !important"};
    `}
  font-size: ${({ fontSize }) => fontSize} !important;
  color: rgba(0, 0, 0, 0.88) !important;
  text-align: ${({ $textAlign }) => $textAlign} !important;
  input,
  textarea {
    font-size: ${({ fontSize }) => fontSize} !important;
    text-align: ${({ $textAlign }) => $textAlign} !important;
  }
  .ant-input-suffix {
    font-size: ${({ fontSize }) => fontSize}!important;
    text-align: ${({ $textAlign }) => $textAlign} !important;
    color: rgba(0, 0, 0, 0.88) !important;
  }
  .ant-input-disabled {
    color: rgba(0, 0, 0, 0.88) !important;
    text-align: ${({ $textAlign }) => $textAlign} !important;
  }
`;

const StyledInput = styled(AntdInput)`
  ${shared}
  ${({ size }) =>
    size === "small" &&
    css`
      height: 24px !important;
    `}
  ${({ size, suffix }) =>
    size === "small" &&
    !suffix &&
    css`
      height: 24px !important;
      padding: 2px 4px !important;
    `}
`;

const StyledTextArea = styled(AntdInput.TextArea)`
  ${shared}
`;

const StyledText = styled.p`
  margin: 0;
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #5499de;
`;

Input.propTypes = {
  className: string,
  fontSize: string,
  textAlign: string,
  textArea: bool,
  isMarginRate: bool,
  placeholder: string,
  warningInput: bool,
  customContent: oneOfType([string, node]),
};

Input.defaultProps = {
  className: "",
  fontSize: "14px",
  textAlign: "left",
  textArea: false,
  isMarginRate: false,
  placeholder: undefined,
  warningInput: false,
  customContent: null,
};

export default Input;
